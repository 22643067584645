import React from 'react'
import { useLocation } from 'react-router-dom'
import VersionNotification from './BannerVersion'

const urlsAvailable = ['', 'perfil']
/**
 * VersionNotificationWrapper component

 * @constructor
 */
const VersionNotificationWrapper = () => {
  const location = useLocation()
  const currentPath = location.pathname.split('/').pop() // Get the last part of the path

  const shouldShowNotification = urlsAvailable.includes(currentPath)

  return (
    <>
      {shouldShowNotification && <VersionNotification currentModuleRoute={currentPath} />}
    </>
  )
}

export default VersionNotificationWrapper
