import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { Paragraph, Icon, Dropdown } from 'src/components'
import {
  faEdit,
  faExclamationTriangle,
  faPrint,
  faTable,
  faTrash,
  faWifi,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { PrintConnectionTypeEnum } from 'src/enums/printerEnum'

interface Props {
  print: IPrinter
  onPrint: () => void
  onConnect: (host: string) => void
  onDisconnectPrint: () => void
  showAdminActions?: boolean
  loading?: boolean
  disabled?: boolean
  // Admin actions
  onEdit?: () => void
  onConfig?: () => void
  onDelete?: () => void
}

/**
 * Represents a printer and its actions within the application.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {IPrinter} props.print - The printer data.
 * @param {Function} props.onPrint - Callback function to handle print action.
 * @param {Function} props.onConnect - Callback function to handle printer connection.
 * @param {Function} props.onDisconnectPrint - Callback function to handle printer disconnection.
 * @param {boolean} [props.showAdminActions=false] - Determines if admin actions should be displayed.
 * @param {boolean} [props.loading=false] - Indicates if the component is in loading state.
 * @param {boolean} [props.disabled=false] - Indicates if the component is disabled.
 * @param {Function} [props.onEdit] - Callback function to handle edit action (optional).
 * @param {Function} [props.onConfig] - Callback function to handle configuration action (optional).
 * @param {Function} [props.onDelete] - Callback function to handle delete action (optional).
 * @returns {React.Component} A Printer component that displays printer information and actions.
 */
const Printer = ({
  print,
  showAdminActions = false,
  loading = false,
  disabled = false,
  onPrint,
  onConnect,
  onConfig,
  onEdit,
  onDelete,
  onDisconnectPrint,
}: Props): React.JSX.Element => {
  return (
    <Row>
      <Col xl={10} lg={10} md={9} sm={7} xs={7}>
        <div className={'column'}>
          <Paragraph size={'big'}>{print.alias}</Paragraph>
          <Paragraph bold>
            {print.connectionType}{' '}
            {print.connectionType !== PrintConnectionTypeEnum.WEB &&
              `${
                print.connectionType === PrintConnectionTypeEnum.BLUETOOTH ? 'MAC' : 'IP'
              } ${print.ip}`}
          </Paragraph>
        </div>
      </Col>
      <Col xl={2} lg={2} md={3} sm={5} xs={5}>
        <Row className={'container-buttons'}>
          <Icon
            style={{ marginRight: 3 }}
            spin={loading}
            disabled={disabled}
            icon={faPrint}
            size={'2x'}
            tooltip={'Imprimir'}
            onClick={() => onPrint()}
          />

          {print.connected && print.connectionType === PrintConnectionTypeEnum.EPSON && (
            <Icon
              tooltip={'Desconectar'}
              style={{ marginRight: 3 }}
              size={'2x'}
              icon={faWindowClose}
              onClick={() => onDisconnectPrint()}
            />
          )}

          {!print.connected && print.error && (
            <Icon
              style={{ marginRight: 3 }}
              icon={faWifi}
              size={'2x'}
              tooltip={'Conectar impresora'}
              onClick={() => {
                let host: string
                if (print.connectionType === PrintConnectionTypeEnum.ESC_POS)
                  host = `${print.host}:6744`
                else host = print.ip
                onConnect(host)
              }}
            />
          )}

          {showAdminActions && (
            <Dropdown
              disabled={disabled}
              items={[
                { title: 'Editar', icon: faEdit, action: () => onEdit() },
                { title: 'Configurar', icon: faTable, action: () => onConfig() },
                { title: 'Eliminar', icon: faTrash, action: () => onDelete() },
              ]}
            />
          )}
        </Row>
      </Col>

      {print.error && (
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className={'d-flex'}>
          <Icon icon={faExclamationTriangle} tooltip={'error'} color={'red'} />
          <Paragraph className={'ml-3 red'}>Error: {print.e}</Paragraph>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <hr style={{ width: '100%' }} />
      </Col>
    </Row>
  )
}
export default Printer
