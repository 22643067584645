import './Paragraph.scss'
import React from 'react'

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  bold?: boolean
  className?: string
  children: React.ReactNode
  dim?: boolean
  italic?: boolean
  size?: 'small' | 'medium' | 'big' | 'extraBig' | 'title'
  upper?: boolean
}

const Paragraph = ({
  bold = false,
  className = '',
  children,
  dim = false,
  italic = false,
  size = 'medium',
  upper = false,
  ...props
}: ParagraphProps) => {
  className = `paragraph ${size} ${bold ? 'bold' : ''} ${dim ? 'dim' : ''} ${
    italic ? 'italic' : ''
  } ${className}`
  return (
    <p className={`paragraph ${className} ${upper ? 'upper-case' : ''}`} {...props}>
      {children}
    </p>
  )
}

export default Paragraph
