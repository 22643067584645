import Service from '../services/inventory.service'
import { executeAction } from './global.actions'

export const types = {
  SET_INFO: 'SET_INFO',
  GET_CHANGE_REQUESTS: 'GET_CHANGE_REQUESTS',
  GET_CHANGE_REQUEST: 'GET_CHANGE_REQUEST',
  ON_CREATE_REQUEST: 'ON_CREATE_REQUEST',
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT: 'GET_REPORT',
  GET_REPORT_ITEM: 'GET_REPORT_ITEM',
  ON_RETURN: 'ON_RETURN',

  ASSIGN_WAREHOUSE_LOCATION: 'ASSIGN_WAREHOUSE_LOCATION',
  LOAD_LOCATIONS_CSV: 'LOAD_LOCATIONS_CSV',
  GET_CSV_LOCATIONS: 'GET_CSV_LOCATIONS',
  GET_PRODUCTS_PRODUCTION: 'GET_PRODUCTS_PRODUCTION',
  GET_PENDING_MISSING_REQUEST_EXCEL: 'GET_PENDING_MISSING_REQUEST_EXCEL',
  DISPATCH_PENDING_INVENTORY: 'DISPATCH_PENDING_INVENTORY',
  LOAD_CSV: 'LOAD_CSV',
  GET_CSV: 'GET_CSV',

  NULLIFY_INVENTORY_REQUEST: 'NULLIFY_INVENTORY_REQUEST',
  GET_INVENTORY_CHANGE_REQUEST_EXCEL: 'GET_INVENTORY_CHANGE_REQUEST_EXCEL',
}

const setInfoRequest = request => ({
  type: types.GET_CHANGE_REQUEST.concat('_SUCCESS'),
  request,
})

export const setInfo = request => dispatch => {
  dispatch(setInfoRequest(request))
}

export const getChangeRequests = params => dispatch => {
  const process = async () => ({
    requests: await Service.getChangeRequests(params),
  })
  dispatch(executeAction(types.GET_CHANGE_REQUESTS, process))
}

export const getReportRequest = (start, end, createdBy, authBy) => dispatch => {
  const process = async () => ({
    report: await Service.getReportRequest(start, end, createdBy, authBy),
  })
  dispatch(executeAction(types.GET_REPORT_REQUEST, process))
}

export const getReportSum = (start, end, type, factor) => dispatch => {
  const process = async () => ({
    report: await Service.getReportSum(start, end, type, factor),
  })
  dispatch(executeAction(types.GET_REPORT, process))
}

export const onCreateInventoryRequest = (request, all) => dispatch => {
  const process = async () => ({
    requestCreate: await Service.onCreateInventoryRequest(request, all),
  })
  dispatch(executeAction(types.ON_CREATE_REQUEST, process))
}

export const onUpdateInventoryRequest = (requestId, status) => dispatch => {
  const process = async () => ({
    request: await Service.onUpdateInventoryRequest(requestId, status),
  })
  dispatch(executeAction(types.GET_CHANGE_REQUEST, process))
}

export const getReport =
  (start, end, warehouses, users, categories, type, advances) => dispatch => {
    console.log(advances)
    const process = async () => ({
      report: await Service.getReport(
        start,
        end,
        warehouses,
        users,
        categories,
        type,
        advances,
      ),
    })
    dispatch(executeAction(types.GET_REPORT, process))
  }

export const getReportItem =
  (start, end, warehouses, users, categories, products, type, advances) => dispatch => {
    const process = async () => ({
      report: await Service.getReportItem(
        start,
        end,
        warehouses,
        users,
        categories,
        products,
        type,
        advances,
      ),
    })
    dispatch(executeAction(types.GET_REPORT_ITEM, process))
  }

export const onReturnInventoryByOfferZone = offerId => dispatch => {
  const process = async () => ({
    return: await Service.onReturnInventoryByOfferZone(offerId),
  })
  dispatch(executeAction(types.ON_RETURN, process))
}

export const assignWarehouseLocations = (request, params) => dispatch => {
  const process = async () => ({
    response: await Service.assignWarehouseLocations(request, params),
  })
  dispatch(executeAction(types.ASSIGN_WAREHOUSE_LOCATION, process))
}

export const massiveLoadLocations =
  (warehouseId, data, currentIndex, onSuccess) => async dispatch => {
    //console.log(data)
    const response = await Service.loadInventoryLocations(warehouseId, data, currentIndex)
    const process = async () => ({
      response,
    })
    dispatch(executeAction(types.LOAD_LOCATIONS_CSV, process))
    onSuccess(response, false)
  }

export const getCSVLocations = (warehouse, listId, all) => dispatch => {
  const process = async () => ({
    excel: await Service.getCSVLocations(warehouse, listId, all),
  })
  dispatch(executeAction(types.GET_CSV_LOCATIONS, process))
}

export /**
 * Actions para la carga masiva de productions al ingreso de inventario
 * @param {Array} data     Lista de productos provenientes del CSV {SKU, cantidad, costo unitario}
 * @param {number} warehouseID     Id de bodega
 * @param {function} onSuccess Callback
 * @returns None
 */
const massiveLoadProductProduction = (data, warehouseID, onSuccess) => async dispatch => {
  const response = await Service.loadProductsProduction(data, warehouseID)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(types.GET_PRODUCTS_PRODUCTION, process))
  onSuccess(response, false)
}

/** Get report excel by pending missing request
 * @param {number} warehouseId Warehouse ID
 * @param {Object} params Filters
 * **/
export const getPendingMissingRequestExcel = (warehouseId, params) => dispatch => {
  const process = async () =>
    await Service.getPendingMissingRequestExcel(warehouseId, params)
  dispatch(executeAction(types.GET_PENDING_MISSING_REQUEST_EXCEL, process))
}

/** Dispatch multiple pending inventories
 * @param {number} warehouseId Warehouse ID
 * @param {Object} products products ID list
 * **/
export const dispatchPendingInventory = (warehouseId, products) => dispatch => {
  const process = async () =>
    await Service.dispatchPendingInventory(warehouseId, products)
  dispatch(executeAction(types.DISPATCH_PENDING_INVENTORY, process))
}

/**
 * Action to UP inventory to warehouse
 * @param {number} warehouseId Warehouse Id to up inventory
 * @param {Object} data List of products to up inventory
 * @param {number} currentIndex Index of the tuple
 * @param {function} onSuccess Return response
 * @returns
 */
export const massiveLoadInventory =
  (warehouseId, data, currentIndex, onSuccess) => async dispatch => {
    const response = await Service.loadInventory(warehouseId, data, currentIndex)
    const process = async () => ({
      response,
    })
    dispatch(executeAction(types.LOAD_CSV, process))
    onSuccess(response)
  }

/**
 * Action to get CSV File of items inventory
 * @returns
 */
export const getCSVInventoryProducts = () => dispatch => {
  const process = async () => ({
    excel: await Service.getCSVInventoryProducts(),
  })
  dispatch(executeAction(types.GET_CSV, process))
}

export const nullifyInventoryRequest = requestId => dispatch => {
  const process = async () => ({
    request: await Service.nullifyInventoryRequest(requestId),
  })
  dispatch(executeAction(types.NULLIFY_INVENTORY_REQUEST, process))
}

/**
 * Get Inventory Change Request Excel
 * @param params  Request params
 * @returns {(function(*): void)|*}
 */
export const getInventoryChangeRequestExcel = params => dispatch => {
  const process = async () => await Service.getInventoryChangeRequestExcel(params)
  dispatch(executeAction(types.GET_INVENTORY_CHANGE_REQUEST_EXCEL, process))
}
