import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, saveCurrentCompany } from 'src/actions/user.actions'
import {
  selectMenuCollapsed,
  selectMenuGroup,
  selectMenuRoutes,
  selectMenuSelected,
} from 'src/selectors/dashboard.selector'
import { expandMenu, selectMenu, setGroup } from 'src/actions/dashboard.actions'
import {
  Accordion,
  useAccordionButton as useAccordionToggle,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'
import LogoutIcon from '../../assets/v2/icon-logout.svg'
import HouseIcon from '../../assets/v2/icon-home.svg'
import arrow from '../../assets/arrow-up.svg'
import users from '../../assets/v2/icon-users.svg'

import { selectCurrentUser } from 'src/selectors/user.selector'

import CompanyList from './CompanyList'
import { history } from 'src/App'
import { selectGetSingleCompany } from 'src/selectors/company.selector'
import IconCompany from 'src/assets/v2/icon-company-card.svg'
import { Env, environment } from 'src/environment'

function RenderItems({
  eventKey,
  option,
  onSelect,
  collapsed,
  tooltip,
  expanded,
  selected,
  onExpand,
  onClick,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => undefined)

  return (
    <li
      key={eventKey}
      style={{ listStyle: 'none' }}
      className={collapsed ? '' : 'border-bottom'}
      onClick={() => {
        if (onClick) onClick(expanded !== eventKey ? eventKey : undefined)
        if (collapsed && onExpand) onExpand(!collapsed)
        decoratedOnClick()
      }}>
      <div style={{ padding: '10px 0', position: 'relative' }} className={'navigation'}>
        <Row>
          <Col xs={2} style={{ textAlign: 'left', paddingLeft: 20 }}>
            <OverlayTrigger overlay={tooltip} placement={'right'} key={option.id}>
              <img className={'menu-icon'} src={option.icon} alt={option.text} />
            </OverlayTrigger>
          </Col>
          <Col xs={10} style={{ paddingTop: 5, paddingLeft: 20 }}>
            {option.text}
          </Col>
        </Row>
        <div style={{ position: 'absolute', right: '5%', top: '20%' }}>
          <img
            src={arrow}
            alt={'arrow'}
            style={{
              transform: expanded === eventKey ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </div>
      </div>
      <Accordion.Collapse eventKey={eventKey}>
        <div
          className={`pi-more border-top module-detail`}
          onClick={e => e.stopPropagation()}>
          {option.modules &&
            option.modules.map((p, i) => (
              <div
                key={i}
                className={`${selected === p.id ? 'activo' : ''}`}
                style={{ paddingLeft: 15, position: 'relative' }}>
                <Link to={`${process.env.PUBLIC_URL}${p.link}`} key={i}>
                  <Row
                    onClick={() => {
                      onSelect(p)
                    }}>
                    <Col xs={10} style={{ padding: 10 }}>
                      <h6 style={{ margin: 4 }}>{p.text}</h6>
                    </Col>
                  </Row>
                </Link>
                {selected === p.id && <div className={'module-detail-selected'} />}
              </div>
            ))}
        </div>
      </Accordion.Collapse>
    </li>
  )
}

const Menu2 = () => {
  const dispatch = useDispatch()
  const routes = useSelector(selectMenuRoutes)
  const user = useSelector(selectCurrentUser)
  const selected = useSelector(selectMenuSelected)
  const collapsed = useSelector(selectMenuCollapsed)
  const group = useSelector(selectMenuGroup)

  const currentCompany = useSelector(selectGetSingleCompany)

  const tooltip = text => (collapsed ? <Tooltip id="tooltip"> {text} </Tooltip> : <div />)

  const version = process.env.REACT_APP_VERSION
    ? `V${process.env.REACT_APP_VERSION}`
    : environment

  return (
    <div className={'d-flex h-100'} onClick={e => e.stopPropagation()}>
      <div className={!collapsed ? 'sidebar-companies' : 'sidebar-companies-collapse'}>
        <CompanyList collapsed={collapsed} />
      </div>
      <div className={!collapsed ? 'sidebar-modules' : 'sidebar-modules-collapse'}>
        <ul style={{ padding: 0, margin: 0, height: '100%' }}>
          <li
            onClick={() => dispatch(setGroup(undefined, true))}
            className={`${selected === 0 ? 'activo' : ''} ${
              !collapsed ? 'border-bottom' : ''
            }`}>
            <div style={{ padding: '10px 0', overflow: 'hidden' }}>
              <Link className={'navigation'} to={`#`} onClick={() => history.push(`/`)}>
                <Row>
                  <Col
                    xs={2}
                    className={`company-default-icon ${collapsed ? 'collapsed' : ''}`}
                    style={{ textAlign: 'left', paddingLeft: 20 }}>
                    <OverlayTrigger placement="right" overlay={tooltip('Inicio')}>
                      {currentCompany.logo ? (
                        <img
                          className={'menu-icon'}
                          src={currentCompany.logo.url}
                          alt={currentCompany.name}
                          width={'100%'}
                          height={'70px'}
                          style={{ objectFit: 'contain' }}
                        />
                      ) : (
                        <img className={'menu-icon'} src={HouseIcon} alt={'Inicio'} />
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col style={{ marginLeft: '5px' }}>
                    <div
                      className={`sidebar-company ${collapsed ? 'collapsed' : ''}`}
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                      }}>
                      <div
                        className={`sidebar-company-info ${collapsed ? 'collapsed' : ''}`}
                        style={{
                          left: 0,
                          bottom: 0,
                          right: 0,
                          top: 10,
                          position: 'absolute',
                        }}>
                        <img
                          className="circular"
                          src={
                            currentCompany.logo ? currentCompany.logo.url : IconCompany
                          }
                          alt={currentCompany?.name}
                          width="50"
                          height="50"
                        />
                        <div className={'mt-1 account-content'}>
                          <h6>{currentCompany?.name}</h6>
                        </div>
                        <div className={'mt-1 account-content'}>{user?.name}</div>
                        <div className={'mt-1 account-content'}>{user?.email}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          </li>
          {/*<li className={!collapsed ? 'border-bottom' : ''}>
                        <div style={{padding: '10px 0'}}>
                            <Link className={'navigation'} to={'#'} onClick={()=> {setShowNew(true)}}>
                                <Row>
                                    <Col xs={2} style={{textAlign: 'left', paddingLeft: 20}}>
                                        <OverlayTrigger placement="right" overlay={tooltip('Información')}>
                                            <img className={'menu-icon'} src={QuestionIcon} alt={'information'}/>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col xs={10} style={{paddingTop:5, paddingLeft: 20}}>
                                        Información
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </li>*/}
          {currentCompany?.owner === user?.id && (
            <li className={!collapsed ? 'border-bottom' : ''}>
              <div style={{ padding: '10px 0' }}>
                <Link
                  className={'navigation'}
                  to={'/perfil'}
                  onClick={() => dispatch(expandMenu(true))}>
                  <Row>
                    <Col xs={2} style={{ textAlign: 'left', paddingLeft: 20 }}>
                      <OverlayTrigger placement="right" overlay={tooltip('Perfil')}>
                        <img className={'menu-icon'} src={users} alt={'Perfil'} />
                      </OverlayTrigger>
                    </Col>
                    <Col xs={10} style={{ paddingTop: 5, paddingLeft: 20 }}>
                      Perfil
                    </Col>
                  </Row>
                </Link>
              </div>
            </li>
          )}
          {routes
            .filter(f => f.show)
            //.sort((b,a) => (a.id-b.id))
            .map((option, index) => (
              <RenderItems
                collapsed={collapsed}
                option={option}
                eventKey={++index}
                key={++index}
                selected={selected}
                tooltip={tooltip(option.text)}
                onSelect={option => dispatch(selectMenu(option.id))}
                expanded={group}
                onExpand={value => dispatch(expandMenu(value))}
                onClick={group => dispatch(setGroup(group))}
              />
            ))}
          <li
            style={{ padding: '10px 0' }}
            onClick={() => {
              dispatch(selectMenu(undefined))
              dispatch(saveCurrentCompany(0))
            }}>
            <Link
              className={'navigation'}
              to={`${process.env.PUBLIC_URL}/`}
              onClick={() => dispatch(logout())}>
              <Row>
                <Col xs={2} style={{ textAlign: 'left', paddingLeft: 20 }}>
                  <OverlayTrigger placement="right" overlay={tooltip('Salir')}>
                    <img className={'menu-icon'} src={LogoutIcon} alt={'salir'} />
                  </OverlayTrigger>
                </Col>
                <Col xs={10} style={{ paddingTop: 5, paddingLeft: 20 }}>
                  Salir
                </Col>
              </Row>
            </Link>
          </li>
          <li>
            <Row>
              <Col>
                <div
                  style={{
                    color: 'black',
                    textAlign: 'center',
                    fontSize: 15,
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    dispatch(expandMenu(true))
                    history.push('/informacion')
                  }}>
                  Kolo © {version}
                </div>
              </Col>
            </Row>
            {environment !== Env.prod && (
              <Row>
                <Col>
                  <div
                    style={{
                      color: 'black',
                      textAlign: 'center',
                      fontSize: 15,
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      dispatch(expandMenu(true))
                      history.push('/playground')
                    }}>
                    Playground
                  </div>
                </Col>
              </Row>
            )}
          </li>
        </ul>
      </div>
      {/*<ModalInformation show={showNew} onHide={onHideModal}/>*/}
    </div>
  )
}

export default Menu2
