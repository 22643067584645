import {
  formatDateFromMillis,
  formatNumberWithCommas,
  formatUtcDateFromMillis,
} from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'
import { getFormattedIds, haveAnyValue } from 'src/utils/utilitiesV2'
import { felDocumentType } from 'src/enums/felDocumentTypes'
import { parseToItemsToTickets } from 'src/content/Prints/PrintFunctions'
import { getItemsGroupedByTag } from 'src/content/Restaurant/POSFunctions'

const registerItem = (quantity, name, description, value, withoutAmounts?: boolean) => {
  const response = []

  quantity = String(quantity)
  while (quantity.length < 5) {
    quantity += ' '
  }
  response.push({ type: 5, value: quantity })

  name = String(name)
  while (name.length < 32) {
    name += ' '
  }
  response.push({ type: 5, value: name })
  let total = ''
  if (!withoutAmounts) {
    const spaces = 11 - value.length
    while (total.length < spaces + 1) total += ' '
    total += toMoney(value)
  }

  response.push({ type: 5, value: `${total}\n` })
  if (description) response.push({ type: 5, value: `\t${description}\n` })
  return response
}

export const printCommandTicket = (
  items: any,
  waiterName: string,
  tableOrder,
  date,
  correlative: string,
  logo,
) => {
  const { posType, posName, tableNumber, description, footerPhrase } = tableOrder

  const logoData: IDataPrint[] = []
  if (logo)
    logoData.push(
      ...[
        {
          type: 9,
          value: {
            context: logo,
            width: 300,
            height: 175,
          },
        },
      ],
    )

  const data: IDataPrint[] = [
    ...logoData,
    { type: 5, value: correlative },
    { type: 1, value: 1 },
    { type: 2, value: 2 },
    { type: 3, value: true },
    { type: 4, value: 1 },
    { type: 5, value: posName },
    { type: 4, value: 2 },
    { type: 1, value: 2 },
    { type: 5, value: posType === 1 ? 'Solicitud de producción\n' : 'Ticket\n' },
    { type: 2, value: 1 },
    {
      type: 5,
      value: (posType === 1 ? 'Terminal: ' : 'Placas: ') + tableNumber + '\n',
    },
  ]
  if (posType === 2) data.push({ type: 5, value: `${description}\n` })
  data.push(
    ...[
      {
        type: 5,
        value: `${(posType === 1 ? 'Mesero: ' : 'Usuario: ') + waiterName}\n`,
      },
      { type: 1, value: 1 },
      { type: 6, value: date },
      { type: 7 },
    ],
  )

  const itemsGroup = []
  items.forEach((i, index) => {
    const { groupValue, quantity } = i
    const igIndex = itemsGroup.findIndex(ig => ig.groupValue === (groupValue || index))
    if (igIndex > -1) {
      itemsGroup[igIndex].quantity += quantity
    } else
      itemsGroup.push({
        ...i,
        groupValue: groupValue || index,
      })
  })

  const groupedItemsByTag = getItemsGroupedByTag(itemsGroup)

  groupedItemsByTag.forEach(({ tag, items }) => {
    data.push({ type: 2, value: 1 })
    data.push({ type: 3, value: true })
    data.push({ type: 11, value: `Ticket:`, otherValue: tag })

    data.push(
      ...[
        { type: 3, value: false },
        { type: 5, value: 'CTD', min: 5 },
        { type: 5, value: 'Descripción' },
        { type: 1, value: 1 },
      ],
    )

    items.forEach(({ name, quantity, supplies, commentaries, commentary }) => {
      data.push({ type: 3, value: true })
      data.push({ type: 5, value: String(quantity), min: 5 })
      data.push({ type: 5, value: name })
      data.push({ type: 1, value: 1 })
      data.push({ type: 3, value: false })

      const allCommentaries = []
      if (haveAnyValue(commentary)) allCommentaries.push(commentary)
      if (haveAnyValue(commentaries)) allCommentaries.push(...commentaries)

      allCommentaries.forEach(c => {
        const comment = '   '.concat(c).concat('\n')
        data.push({ type: 5, value: comment })
      })

      if (supplies && supplies.length > 0) {
        supplies
          .filter(s => s.quantity !== 0)
          .forEach(s => {
            const ex = `    (${s.quantity}) ${s.name}\n`
            data.push({ type: 5, value: ex })
          })
      }
    })
  })

  data.push({ type: 7 })

  if (footerPhrase) {
    data.push({ type: 2, value: 2 })
    data.push({ type: 10, value: `${footerPhrase}\n` })
  }

  return data
}

const generateAuxiliarDataToPrint = ({
  type,
  align,
  bold,
}: IPrintInstruction): IDataPrint[] => {
  const data: IDataPrint[] = [{ type: 3, value: bold }]

  let alignValue: number
  switch (align) {
    case 'center':
      alignValue = 2
      break
    case 'right':
      alignValue = 3
      break
    default:
      alignValue = 1
      break
  }
  data.push({ type: 2, value: alignValue })

  let textSize = 2
  switch (type) {
    case 'text':
    case 'sub_title':
    default:
      textSize = 2
      break
    case 'title':
      textSize = 1
      break
  }
  data.push({ type: 4, value: textSize })

  return data
}

export const invoiceTicketByInstructions = (
  instructions: IPrintInstruction[],
  logo?: any,
): IDataPrint[] => {
  const data: IDataPrint[] = []

  if (logo)
    if (logo) {
      data.push({
        type: 9,
        value: {
          context: logo,
          x: 0,
          y: 0,
          width: 300,
          height: 175,
        },
      })
    }

  const splitLIne = '\n'

  instructions?.forEach(instruction => {
    data.push(...generateAuxiliarDataToPrint(instruction))
    switch (instruction.type) {
      case 'text':
      case 'sub_title':
      case 'title':
        {
          let value = ''
          if (haveAnyValue(instruction.space)) {
            for (let i = 0; i < instruction.space; i++) {
              value += '   '
            }
          }
          value += instruction.value

          if (instruction.align === 'space_between')
            data.push({
              type: 11,
              value,
              otherValue: instruction.secondValue,
            })
          else data.push({ type: 5, value: value + splitLIne })
        }
        break
      case 'table':
        {
          const itemsInDetail: IItemsToPrint[] = instruction?.items?.map(
            (item: IPrintInstructionTableItem) => ({
              quantity: item.quantity,
              name: item.description,
              subtotal: item.amount,
              discount: 0,
            }),
          )
          data.push(...parseToItemsToTickets(itemsInDetail))
        }
        break
      case 'separator':
        data.push({ type: 7, value: '' })
        break
      case 'cut':
        data.push({ type: 8 })
        break
    }
  })

  return data
}

export const invoiceTicket = (country: number, invoices: IInvoice[], logo) => {
  const data = []
  invoices.forEach((invoice, index) => {
    const cert = invoice.uuId !== null && invoice.uuId !== ''
    const identifications: IdentificationOption[] = getFormattedIds(
      country,
      invoice.nit,
      invoice.nrc,
      invoice.cui,
      invoice.foreignId,
    )

    if (logo) {
      data.push({
        type: 9,
        value: {
          context: logo,
          x: 0,
          y: 0,
          width: 300,
          height: 175,
        },
      })
    }

    data.push(
      ...[
        { type: 2, value: 2 },
        { type: 3, value: true },
        { type: 4, value: 1 },
        {
          type: 5,
          value: `${
            invoice.emisorName
              ? invoice.emisorName
              : invoice.companyDetail
              ? invoice.companyDetail.name
              : 'Desconocido'
          }\n`,
        },
        { type: 3, value: false },
        { type: 4, value: 2 },
      ],
    )
    if (cert) data.push({ type: 5, value: `NIT: ${invoice.companyDetail.nit}\n` })
    data.push(
      ...[
        { type: 5, value: `${invoice.comercialName || invoice.companyDetail.name}\n` },
        {
          type: 5,
          value: `${invoice.commercialAddress || invoice.companyDetail.address}\n\n`,
        },
        { type: 2, value: 2 },
        { type: 3, value: true },
      ],
    )

    let typeInvoice = 'ENVIÓ'
    if (cert) {
      switch (invoice.documentType) {
        case 1:
          if (invoice.tipoAfiliacion === 3) typeInvoice = 'Factura Pequeño Contribuyente'
          else if (invoice.felDocumentType === felDocumentType.SUJETO_EXC)
            typeInvoice = 'Factura de sujeto excluido'
          else typeInvoice = 'FACTURA'
          break
        case 2:
          typeInvoice = 'Factura Especial'
          break
        case 4:
          typeInvoice = 'NOTA DE CRÉDITO'
          break
        case 5:
          typeInvoice = 'RECIBO'
          break
        default:
          typeInvoice = 'ENVIÓ'
          break
      }
    }

    if (cert) {
      data.push(
        ...[
          { type: 7, value: 'Documento Tributario Electrónico FEL' },
          {
            type: 5,
            value: `${typeInvoice}\n`,
          },
          { type: 3, value: false },
          { type: 5, value: 'Número de Autorización\n' },
          {
            type: 5,
            value: `${invoice.uuId || 'ESTE DOCUMENTO NO ES UNA FACTURA ELECTRÓNICA'}\n`,
          },
          { type: 5, value: `Serie: ${invoice.authSeries}\n` },
          { type: 5, value: `Número: ${invoice.authNumber}\n` },
          {
            type: 5,
            value: `Fecha de Emisión: ${formatDateFromMillis(
              invoice.createdAt,
              6,
              true,
            )}\n`,
          },
          {
            type: 5,
            value: `Fecha de Autorización: ${formatDateFromMillis(invoice.date, 6)}\n\n`,
          },
          { type: 3, value: true },
        ],
      )
    } else {
      data.push({ type: 7, value: typeInvoice })
    }

    data.push(
      ...[
        { type: 7, value: '-DATOS DEL CLIENTE-' },
        { type: 3, value: false },
        { type: 5, value: `Nombre: ${invoice.name}\n` },
      ],
    )
    if (cert) {
      identifications.forEach(clientID =>
        data.push({
          type: 5,
          value: `${clientID.label}: ${clientID.value}\n`,
        }),
      )
    }
    data.push({ type: 5, value: `Dirección: ${invoice.address}\n` })
    if (invoice.description)
      data.push({ type: 5, value: `Descripción: ${invoice.description}\n` })
    if (invoice.commentary)
      data.push({ type: 5, value: `Comentario: ${invoice.commentary}\n` })
    data.push(...[{ type: 7 }, { type: 3, value: true }])

    const itemsInDetail: IItemsToPrint[] = invoice.invoiceDetails
      .filter(item => !item.viewInSummary)
      .map((item: IInvoiceDetail) => ({
        quantity: item.quantity,
        name:
          item.description +
          (haveAnyValue(item.variations) ? ` (${item.variations})` : ''),
        subtotal: item.amount + item.discount,
        discount: item.discount,
      }))

    data.push(...parseToItemsToTickets(itemsInDetail))

    const subTotals = getSubtotalsByInvoice(invoice, itemsInDetail)
    subTotals.forEach(subtotal =>
      data.push({
        type: 11,
        value: subtotal.name,
        otherValue: formatNumberWithCommas(subtotal.subtotal),
      }),
    )

    if (cert) {
      data.push(
        ...[
          { type: 3, value: true },
          { type: 7, value: 'DATOS DEL CERTIFICADOR' },
          { type: 3, value: false },
          {
            type: 5,
            value: `Certificador: ${invoice.nameCert || 'Sin certificador'}\n`,
          },
          {
            type: 5,
            value: `NIT: ${invoice.nitCert || 'Sin certificador'}\n`,
          },
        ],
      )
      if (invoice.phraseType)
        data.push({ type: 5, value: `${invoice.phraseType.phrase}\n` })
    }
    if (invoices.length > 0) {
      if (invoices.length !== index + 1) data.push({ type: 8 })
    }
  })

  return data
}

const getSubtotalsByInvoice = (
  invoice: IInvoice,
  inDetail: IItemsToPrint[],
): IItemsToPrint[] => {
  const subtotal = inDetail.reduce((a, value) => a + value.subtotal, 0)
  const discount = inDetail.reduce((a, value) => a + value.discount, 0)
  const inSummary = invoice.invoiceDetails.filter(item => item.viewInSummary)

  const labelTotal = 'Total'
  const labelSubtotal = 'Subtotal'

  const response: IItemsToPrint[] = [
    { name: discount > 0 || inSummary.length > 0 ? labelSubtotal : labelTotal, subtotal },
  ]

  if (discount > 0) {
    response.push({ name: 'Descuentos', subtotal: discount })
    response.push({
      name: inSummary.length > 0 ? 'Subtotal con descuento' : labelTotal,
      subtotal: subtotal - discount,
    })
  }

  if (inSummary.length > 0) {
    inSummary.forEach(item =>
      response.push({
        name: item.description,
        subtotal: item.amount,
      }),
    )
    response.push({ name: labelTotal, subtotal: invoice.total })
  }

  return response
}

export const orderTicket = (
  order: IOrder,
  company: ICompany,
  withoutAmounts: boolean,
) => {
  const data = [
    { type: 2, value: 2 },
    { type: 3, value: true },
    { type: 4, value: 1 },
    { type: 5, value: `${company.commercialName || company.name}\n` },
    { type: 3, value: false },
    { type: 4, value: 2 },
    {
      type: 5,
      value: `${company.id === 791 ? 'AE' : company.id === 860 ? 'IM' : company.name}\n`,
    },
    {
      type: 5,
      value: `${
        company.id !== 791 && (company.id === 860 ? 'Ciudad' : company.address)
      }\n`,
    },
    {
      type: 5,
      value: `Orden NO. ${order.number}
          ' - '
          ${formatUtcDateFromMillis(order.enabledAt || order.createdAt)}\n`,
    },
    { type: 2, value: 1 },
    { type: 7, value: '' },
    {
      type: 5,
      value: `Nombre: ${order?.client.companyName || 'Desconocido'}\n`,
    },
    {
      type: 5,
      value: `NIT: ${order?.client.nit || 'Desconocido'}\n`,
    },
    {
      type: 5,
      value: `Dirección: ${order?.client.address || 'Desconocido'}\n`,
    },
    {
      type: 5,
      value: `Teléfono: ${order?.client.phone || 'Desconocido'}\n`,
    },
    {
      type: 5,
      value: `Descripción: ${order?.description || 'Sin descripción'}\n`,
    },
    {
      type: 5,
      value: `Forma de pago: ${order?.paymentTypeData.name || 'Desconocido'}\n`,
    },

    {
      type: 5,
      value: `Repartidor: ${order?.responsibleUser.name || 'Sin descripción'}\n`,
    },
  ]

  if (order.updatedAt)
    data.push({
      type: 5,
      value: `Fecha de entrega: ${formatUtcDateFromMillis(order.updatedAt)}\n`,
    })

  data.push(
    ...[
      { type: 7, value: true },
      { type: 3, value: true },
    ],
  )

  data.push(
    ...[
      { type: 5, value: 'CT   ' },
      { type: 5, value: 'Desc\t\t\t\t' },
    ],
  )

  if (!withoutAmounts) data.push({ type: 5, value: 'Sub\n' })
  data.push({ type: 3, value: false })
  order.details.forEach(item => {
    data.push(
      ...registerItem(
        Number.parseInt(item.quantity),
        item.productData
          ? item.productData.name
          : item.product
          ? item.product.name
          : 'Desconocido',
        item.categories && item.categories.map(category => category.name + ' '),
        item.total,
        withoutAmounts,
      ),
    )
  })

  if (!withoutAmounts) {
    if (order.surcharge > 0)
      data.push(
        ...registerItem('Recargo', '', null, formatNumberWithCommas(order.surcharge)),
      )
    data.push(...registerItem('Total', '', null, formatNumberWithCommas(order.total)))
  }

  data.push(
    ...[
      { type: 3, value: false },
      { type: 4, value: 2 },
    ],
  )

  if (order.paymentTypeData.id === 2 && !withoutAmounts) {
    data.push(
      ...[
        {
          type: 5,
          value: `Abonos: ${formatNumberWithCommas(order.total - order.balance)}\n`,
        },
      ],
    )
  }

  data.push(
    ...[
      {
        type: 5,
        value: `ESTE DOCUMENTO NO ES UNA FACTURA ELECTRÓNICA\n`,
      },
    ],
  )

  return data
}

const registerDetail = (firstValue, secondValue, thirdValue) => {
  const response = []

  firstValue = String(firstValue)
  while (firstValue.length < 10) {
    firstValue += ' '
  }
  response.push({ type: 5, value: firstValue })

  secondValue = String(secondValue)
  while (secondValue.length < 28) {
    secondValue += ' '
  }
  response.push({ type: 5, value: secondValue })

  let value = ''
  const spaces = 6 - thirdValue.length
  while (value.length < spaces + 1) value += ' '
  value += thirdValue

  response.push({ type: 5, value: `${value}\n` })
  return response
}

export const transferTicket = (transfer: ITransfer) => {
  const data = [
    { type: 2, value: 2 },
    { type: 3, value: true },
    { type: 4, value: 1 },
    { type: 5, value: `Transferencia No. ${transfer.number}\n` },
    { type: 3, value: false },
    { type: 4, value: 2 },
    {
      type: 5,
      value: `${formatUtcDateFromMillis(transfer.enabledAt || transfer.createdAt)}\n`,
    },
    { type: 2, value: 1 },
    { type: 7, value: '' },
    {
      type: 5,
      value: `Bodega saliente: ${transfer.fromWarehouseData?.name || 'Desconocido'}\n`,
    },
    {
      type: 5,
      value: `Bodega entrante: ${transfer.toWarehouseData?.name || 'Desconocido'}\n`,
    },
    { type: 5, value: `Estado: ${transfer.stateName || 'Desconocido'}\n` },
    { type: 5, value: `Solicitado por: ${transfer.solicitedBy || 'Desconocido'}\n` },
    { type: 5, value: `Aprobado por: ${transfer.reviewedBy || 'Desconocido'}\n` },
    {
      type: 5,
      value: `Fecha de aprobación: ${
        transfer.reviewedAt || transfer.movedAt
          ? formatUtcDateFromMillis(transfer.reviewedAt || transfer.movedAt)
          : 'Sin aprobar'
      }\n`,
    },
    { type: 5, value: `Descripción: ${transfer.description || 'Sin descripción'}\n` },
    { type: 5, value: `Cantidad de ítems transferidos: ${transfer.itemQuantity || 0}\n` },
    { type: 5, value: '\n' },
  ]
  transfer.details?.forEach(item => {
    data.push(
      ...registerDetail(
        item.productData ? item.productData.code : 'Desconocido',
        (item.productData ? item.productData.name : 'Desconocido') +
          `${item.commentary !== null ? '\n ' + item.commentary : ''}`,
        item.realQuantity,
      ),
    )
  })

  console.log(data)

  return data
}
