import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal, ProgressBar } from 'react-bootstrap'
import { Button, Empty, Paragraph } from 'src/components'
import { faEdit, faPlus, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getCompanyAddons,
  onValidateUpdateAddon,
  updateAddons,
} from 'src/actions/mentor.actions'
import {
  selectCompanyAddonEntity,
  selectCompanyAddons,
} from 'src/selectors/mentor.selector'

import { mentorPermissions } from 'src/enums/permissions'
import { isAllowed } from 'src/selectors/modules.selector'

import { showAlert } from 'src/actions/alert.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import AddAddons from './AddAddons'
import DetailAddons from './DetailAddons'
import UpdateAddonsErrorModal from './UpdateAddonsErrorModal'

interface Props {
  show: boolean
  companyId: number
  title: string
  onHide: () => void
  demo: boolean
  renewal?: boolean
}

/**
 * @component Component to visualize the addons of a company
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {number} companyId ID of the company to which the addons will be seen
 * @param {string} title Company name and code
 * @param {Function} onHide Close the component
 * @param {boolean} demo Indicate if the company is a DEMO account
 * @param {boolean} renewal Indicate if the company is in renewal process
 * */
const CompanyAddons = ({ show, companyId, title, onHide, demo, renewal }: Props) => {
  const dispatch = useDispatch()
  const demoBehavior = demo || renewal
  const list: MCompanyAddons[] = useSelector(selectCompanyAddons)

  const loadingSeeAddons = useSelector(state =>
    loadingSelector([actionTypes.GET_COMPANY_ADDONS])(state),
  )

  const companyAddonsEntityResponse: MCompanyAddonsEntityResponse = useSelector(
    selectCompanyAddonEntity,
  )
  const loadingValidate: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_VALIDATE_UPDATE_ADDON])(state),
  )
  const errorValidate = useSelector(state =>
    hasErrors([actionTypes.ON_VALIDATE_UPDATE_ADDON])(state),
  )

  const loadingUpdate: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_UPDATE_ADDON])(state),
  )
  const errorUpdate = useSelector(state =>
    hasErrors([actionTypes.ON_UPDATE_ADDON])(state),
  )

  const loadingChanges = loadingValidate || loadingUpdate

  /** PERMISSIONS **/
  const canAddAddon = useSelector(state =>
    isAllowed(state, [mentorPermissions.canAddAddon]),
  )
  const canEditAddon = useSelector(state =>
    isAllowed(state, [mentorPermissions.canEditAddon]),
  )

  const [flags, setFlags] = useState({ get: false, validate: false, update: false })

  const [companyAddons, setCompanyAddons] = useState<MCompanyAddons[]>([])

  const [openModalAddAddons, setOpenAddAddons] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [openModalExtraAddons, setOpenModalExtraAddons] = useState<boolean>(false)

  useEffect(() => {
    if (!show) return
    setUp()
  }, [show])

  useEffect(() => {
    if (loadingSeeAddons) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      setCompanyAddons(Object.assign([], list))
    }
  }, [loadingSeeAddons])

  useEffect(() => {
    if (loadingValidate) setFlags({ ...flags, validate: true })
    else if (flags.validate) {
      setFlags({ ...flags, validate: false })
      if (errorValidate) dispatch(showAlert({ ...handlerError(errorValidate.message) }))
      else {
        if (!companyAddonsEntityResponse.principal)
          dispatch(
            showAlert({
              ...handlerError(
                'No es posible actualizar los addons ya que actualmente posee más ítems de los permitidos.',
              ),
              onConfirm: () => setOpenModalExtraAddons(true),
            }),
          )
        else {
          const request: MCompanyAddons[] = companyAddons
            .filter(ca => {
              const quantityUpdated: boolean = ca.quantity !== ca.originalQuantity
              const amountUpdated: boolean = ca.price !== ca.originalPrice
              return demoBehavior ? quantityUpdated || amountUpdated : ca.deleted
            })
            .map(ca => ({
              id: ca.id,
              quantity: ca.quantity,
              amount: ca.price * ca.quantity,
            }))
          dispatch(updateAddons(companyId, request))
        }
      }
    }
  }, [loadingValidate])

  useEffect(() => {
    if (loadingUpdate) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })

      const alert = errorUpdate
        ? { ...handlerError(errorUpdate.message) }
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              onRefresh()
              setUp()
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loadingUpdate])

  const setUp = () => dispatch(getCompanyAddons(companyId))

  const onRefresh = () => {
    setOpenAddAddons(false)
    setCompanyAddons([])
    setEdit(false)
    setOpenModalExtraAddons(false)
  }

  const onClose = () => {
    onRefresh()
    onHide()
  }

  const onValidate = () => {
    const request: MCompanyAddonsEntityResponse[] = []

    companyAddons
      .filter(ca => (demoBehavior ? true : ca.deleted))
      .forEach(addon => {
        const minusQuantity: number = demoBehavior
          ? addon.originalQuantity - addon.quantity
          : addon.quantity
        const rIndex = request.findIndex(rt => rt.id === addon.planId)
        if (rIndex >= 0)
          request[rIndex].code = (Number(request[rIndex].code) + minusQuantity).toString()
        else
          request[request.length] = {
            id: addon.planId,
            code: minusQuantity.toString(),
          }
      })

    dispatch(
      showAlert({
        ...handlerInfoWithButtons(
          demoBehavior ? 'Actualizar Addons' : 'Eliminar Addons',
          demoBehavior
            ? 'Está acción puedes repetirla varias veces mientras la cuenta siga en estado DEMO.'
            : '¿Desea eliminar los addons seleccionados? Los addons a eliminar ya no saldrán en la siguiente facturación de la cuenta. Está acción no podrá ser revertida.',
        ),
        onConfirm: () => dispatch(onValidateUpdateAddon(companyId, request)),
      }),
    )
  }

  return (
    <div>
      <Modal
        show={show && !openModalAddAddons && !openModalExtraAddons}
        size={'lg'}
        centered
        onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Addons: {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'custom-modal-body'}>
            {loadingSeeAddons ? (
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            ) : (
              <Row>
                <Col xl={12}>
                  {companyAddons.length === 0 && (
                    <Empty
                      title={'No se han agregado addons'}
                      subtitle={' '}
                      subtitleSecond={' '}
                      buttonTitle={canAddAddon ? 'Añadir' : undefined}
                      onClick={canAddAddon ? () => setOpenAddAddons(true) : undefined}
                    />
                  )}
                </Col>
                <Col xl={12} className={'mb-3'}>
                  <Paragraph>
                    La cuenta está en estado:{' '}
                    {demo ? 'DEMO' : renewal ? 'EN RENOVACIÓN' : 'OPERATIVA'}. Por lo
                    tanto puede{' '}
                    {demoBehavior
                      ? 'modificar las cantidades y montos sin restricción'
                      : 'únicamente disminuir las cantidades'}
                    .
                  </Paragraph>
                </Col>
                <Col xl={12}>
                  <DetailAddons
                    addons={companyAddons}
                    renewal={renewal}
                    demo={demo}
                    edit={edit}
                    loadingValidate={loadingChanges}
                    onChange={addonsUpdated => setCompanyAddons(addonsUpdated)}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        {(canAddAddon || canEditAddon) && (
          <Modal.Footer>
            <Row className={'container-buttons'}>
              {canEditAddon && !edit && (
                <Button color={'accent'} onClick={() => setEdit(true)} icon={faEdit}>
                  Editar
                </Button>
              )}
              {canAddAddon && !edit && (
                <Button onClick={() => setOpenAddAddons(true)} icon={faPlus}>
                  Añadir
                </Button>
              )}

              {edit && (
                <>
                  <Button
                    disabled={loadingChanges}
                    color={'secondary'}
                    onClick={() => {
                      setEdit(false)
                      setUp()
                    }}
                    icon={faWindowClose}>
                    Cancelar
                  </Button>

                  <Button
                    loading={loadingChanges}
                    color={'primary'}
                    onClick={onValidate}
                    icon={faEdit}>
                    Guardar
                  </Button>
                </>
              )}
            </Row>
          </Modal.Footer>
        )}
      </Modal>

      <AddAddons
        show={openModalAddAddons}
        companyId={companyId}
        title={title}
        demo={demo}
        renewal={renewal}
        onHide={(update: boolean) => {
          setOpenAddAddons(false)
          if (update) setUp()
        }}
      />

      <UpdateAddonsErrorModal
        show={openModalExtraAddons}
        items={companyAddonsEntityResponse.items}
        onHide={() => setOpenModalExtraAddons(false)}
      />
    </div>
  )
}
export default CompanyAddons
