import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import Loading from 'src/components/Loading/Loading'
import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import Dropdown from 'src/components/buttons/Dropdown'
import {
  faEdit,
  faFile,
  faFileDownload,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import {
  types,
  getModalPurchase,
  onSetModalPurchase,
  onRefresh,
} from 'src/actions/utilities.actions'

import {
  selectModalPurchase,
  selectModalPurchaseId,
} from 'src/selectors/utilities.selector'

import {
  getPurchaseFields,
  getPurchaseState,
  getPurchaseItems,
} from './getPurchaseFunctions'

import {
  actionTypes,
  onUpdateStatusPurchase,
  printPurchase,
  seePurchasePayments,
  accreditMovementByPurchase,
} from 'src/actions/purchase.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import { showAlert } from 'src/actions/alert.actions'
import { isAllowed } from 'src/selectors/modules.selector'

import { purchasePermissions, expensePermissions } from 'src/enums/permissions'
import { PurchaseStatusEnum } from 'src/enums/purchaseEnums'

import PurchaseInformationDetail from './PurchaseInformationDetail'
import PurchaseEditInformation from './PurchaseEditInformation'
import PurchaseDetailItem from './PurchaseDetailItem'
import PurchaseNullify from '../../PurchaseNullify'
import DetailPayments from '../PurchaseDocumentPayments'
import InventoryPurchase from '../InventoryPurchase'
import PurchaseDetailItemSeries from './PurchaseDetailItemSeries'
import ProvidersAdvances from '../../../Providers/ProvidersAdvances'
import PurchaseConfirm from './PurchaseConfirm'
import { selectCompanyCountry } from 'src/selectors/company.selector'

interface IFlagsProps {
  modal?: boolean
  get?: boolean
  updateStatus?: boolean
  accredit?: boolean
  executeExternalAction?: boolean
}

/**
 *  Render a modal with Purchase details and functions
 * **/
const PurchaseDetail = () => {
  const dispatch = useDispatch()

  const seePayments: boolean =
    // @ts-ignore
    useSelector(state => state.purchase.paymentsPurchaseValues)?.id !== undefined

  const purchaseId: number = useSelector(selectModalPurchaseId)
  const country = useSelector(selectCompanyCountry)

  const purchaseResponse: IPurchase = useSelector(selectModalPurchase)
  const [purchase, setPurchase] = useState<IPurchase>({})

  const loadingGetPurchase: boolean = useSelector(state =>
    loadingSelector([types.GET_MODAL_PURCHASE])(state),
  )
  const hasErrorGetPurchase = useSelector(state =>
    hasErrors([types.GET_MODAL_PURCHASE])(state),
  )

  const loadingDownloadPDF: boolean = useSelector(state =>
    loadingSelector([actionTypes.PRINT_PURCHASE])(state),
  )

  const loadingUpdateStatus: boolean = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_STATUS])(state),
  )
  const hasErrorUpdateStatus = useSelector(state =>
    hasErrors([actionTypes.UPDATE_STATUS])(state),
  )

  const loadingAccreditPurchase: boolean = useSelector(state =>
    loadingSelector([actionTypes.ACCREDIT])(state),
  )
  const hasErrorAccreditPurchase = useSelector(state =>
    hasErrors([actionTypes.ACCREDIT])(state),
  )

  /* PERMISSIONS */

  const canConfirmWithoutPayments = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.confirmWithoutPayments
        : expensePermissions.confirmWithoutPayments,
    ]),
  )
  const canPaymentInConfirm = useSelector(state =>
    isAllowed(state, [purchasePermissions.canPaymentInConfirm]),
  )
  const canPaymentWithBanks = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.canPaymentWithBanks
        : expensePermissions.canPaymentWithBanks,
    ]),
  )
  const canPaymentWithCash = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.canPaymentWithCash
        : expensePermissions.canPaymentWithCash,
    ]),
  )
  const canPaymentWithProviderBalance = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.canPaymentWithProviderBalance
        : expensePermissions.canPaymentWithProviderBalance,
    ]),
  )
  const canViewPayments = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.canViewPayments
        : expensePermissions.canViewPayments,
    ]),
  )
  const canNullify = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.nullifyPurchase
        : expensePermissions.nullifyExpenses,
    ]),
  )
  const viewMonetaryBalances = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.viewMonetaryBalances
        : expensePermissions.viewMonetaryBalances,
    ]),
  )

  const havePermissionInPayments =
    canPaymentWithBanks || canPaymentWithCash || canPaymentWithProviderBalance

  const [flags, setFlags] = useState<IFlagsProps>({
    modal: false,
    get: false,
    updateStatus: false,
    accredit: false,
    executeExternalAction: false,
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editPurchaseInformation, setEditPurchaseInformation] = useState<boolean>(false)
  const [detailToSee, setDetailToSee] = useState<IPurchaseInventory>({})
  const [showInventoryToValidate, setShowInventoryToValidate] = useState<boolean>(false)
  const [showCPPPayment, setShowCPPPayment] = useState<boolean>(false)
  const [clickedOn, setClickedOn] = useState<number>(0)
  const [paymentRequest, setPaymentRequest] = useState<IPurchasePaymentRequest>({})

  const purchaseDetail: IPurchaseDetail[] = purchase?.details || []

  const purchaseItems = getPurchaseItems(purchaseDetail)
  const totalTax: number = purchaseDetail.reduce((acc, item) => acc + item.tax, 0)

  const showPrincipalModal =
    open &&
    !editPurchaseInformation &&
    !detailToSee.show &&
    !seePayments &&
    !showInventoryToValidate &&
    !detailToSee.showSerie &&
    !showCPPPayment &&
    !paymentRequest.show

  const canSeeInventory =
    purchase?.status === PurchaseStatusEnum.CPP ||
    purchase?.status === PurchaseStatusEnum.COMPLETADA

  useEffect(() => {
    if (purchaseId) {
      setFlags({})
      setUp()
    }
  }, [purchaseId])

  useEffect(() => {
    if (loadingGetPurchase) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (hasErrorGetPurchase)
        dispatch(
          showAlert({
            ...handlerError(hasErrorGetPurchase.message),
            onConfirm: () => onClose(),
          }),
        )
      else {
        const isPurchase = purchaseResponse.paymentType !== 3
        let inventoryType: string
        if (isPurchase) inventoryType = purchaseResponse.removeInventory ? 'No' : 'Si'
        else inventoryType = !purchaseResponse.removeInventory ? 'No' : 'Si'

        setPurchase({
          ...purchaseResponse,
          isPurchase,
          paymentTypeName: purchaseResponse.addPayments ? 'Si' : 'No',
          statusName: getPurchaseState(purchaseResponse.status),
          subtotal: purchaseResponse.total + purchaseResponse.discount,
          inventoryType,
        })
      }
    }
  }, [loadingGetPurchase])

  useEffect(() => {
    if (loadingUpdateStatus) setFlags({ ...flags, updateStatus: true })
    else if (flags.updateStatus) {
      setFlags({
        ...flags,
        updateStatus: false,
        executeExternalAction: !hasErrorUpdateStatus,
      })

      const alert = hasErrorUpdateStatus
        ? {
            ...handlerError(hasErrorUpdateStatus.message),
          }
        : {
            ...handlerSuccess('Documento actualizado exitosamente.'),
            onConfirm: () => setUp(),
          }

      dispatch(showAlert(alert))
    }
  }, [loadingUpdateStatus])

  useEffect(() => {
    if (loadingAccreditPurchase) setFlags({ ...flags, accredit: true })
    else if (flags.accredit) {
      setFlags({
        ...flags,
        accredit: false,
        executeExternalAction: !hasErrorAccreditPurchase,
      })
      const alert = hasErrorAccreditPurchase
        ? {
            ...handlerError(hasErrorAccreditPurchase.message),
          }
        : {
            ...handlerSuccess('Operación realizada con éxito.'),
            onConfirm: () => setUp(),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingAccreditPurchase])

  const setUp = () => {
    setOpen(true)
    dispatch(getModalPurchase(purchaseId))
  }

  const onCancel = () =>
    dispatch(
      showAlert({
        ...handlerInfoWithButtons(
          'Rechazar',
          `¿Desea rechazar el documento: ${purchase?.invoice}? Está acción no podrá ser revertida.`,
        ),
        onConfirm: () => {
          setClickedOn(PurchaseStatusEnum.CANCELADA)
          dispatch(onUpdateStatusPurchase(purchaseId, PurchaseStatusEnum.CANCELADA, {}))
        },
      }),
    )

  const onValidateInventory = () =>
    dispatch(
      showAlert({
        ...handlerInfoWithButtons(
          'Validar inventario',
          `Al validar el inventario de la compra, se registrará una cuenta por pagar por el monto de la compra.`,
        ),
        onConfirm: () => {
          setShowInventoryToValidate(false)
          onConfirm({ skip: true })
        },
      }),
    )

  const onApprove = () => {
    if (
      purchase.status === PurchaseStatusEnum.INGRESADA &&
      (!canPaymentInConfirm || !purchase.addPayments)
    )
      dispatch(
        showAlert({
          ...handlerInfoWithButtons(
            'Aprobar',
            `¿Desea aprobar el documento: ${
              purchase?.invoice
            }? La transacción quedará como ${
              purchase.addPayments ? 'cuenta por pagar' : 'completada'
            }`,
          ),
          onConfirm: () => onConfirm({}),
        }),
      )
    else if (havePermissionInPayments) setShowCPPPayment(true)
  }

  const onConfirm = (payments: IPurchasePaymentRequest) => {
    setShowCPPPayment(false)
    const request: IPurchasePaymentRequest = {
      updatePatrimonial: true,
      dollar: false,
      skip: payments.skip,
      userBalances: payments.userBalances,
      movBanks: payments.movBanks,
      providerBalance: payments.providerBalance,
      noInventory: purchase.removeInventory,
      noDocument: !purchase.addPayments,
    }
    setClickedOn(PurchaseStatusEnum.COMPLETADA)
    if (purchase?.status === PurchaseStatusEnum.CPP)
      dispatch(accreditMovementByPurchase(purchaseId, request))
    else setPaymentRequest({ ...request, show: true })
  }

  const getLoadingValue = (type: number): boolean =>
    (loadingUpdateStatus || loadingAccreditPurchase) && type === clickedOn

  const getDisabledValue = (type: number): boolean =>
    (loadingUpdateStatus || loadingAccreditPurchase) && type !== clickedOn

  const onClose = () => {
    if (loadingUpdateStatus || loadingAccreditPurchase) return
    dispatch(onSetModalPurchase(null))
    dispatch(onRefresh(flags.executeExternalAction))
    setOpen(false)
  }

  const actions = (
    <Dropdown
      loading={loadingDownloadPDF}
      items={[
        {
          title: 'Editar datos',
          icon: faEdit,
          action: () => setEditPurchaseInformation(true),
        },
        {
          title: 'Descargar PDF',
          icon: faFileDownload,
          action: () =>
            dispatch(
              printPurchase(
                purchaseId,
                `${purchase.isPurchase ? 'Compra' : 'Gasto'} ${purchase.invoice}`,
              ),
            ),
        },
        {
          show:
            (purchase?.status === PurchaseStatusEnum.CPP ||
              purchase?.status === PurchaseStatusEnum.COMPLETADA) &&
            canViewPayments,
          title: 'Ver pagos',
          icon: faFile,
          action: () =>
            dispatch(seePurchasePayments({ id: purchaseId, invoice: purchase.invoice })),
        },
        {
          show:
            (purchase?.status === PurchaseStatusEnum.CPP ||
              purchase?.status === PurchaseStatusEnum.COMPLETADA) &&
            canNullify,
          title: 'Anular',
          icon: faTrash,
          action: () => setDetailToSee({ show: true, readDetail: false }),
        },
      ]}
    />
  )

  const detailInformation = (
    <Card white title={'Información general'} button={actions}>
      <Row>
        {getPurchaseFields(viewMonetaryBalances, purchase?.isPurchase, country?.id).map(
          f => (
            <Col key={f.value} xl={12} lg={12} md={12} sm={12} xs={12}>
              <PurchaseInformationDetail {...f} value={purchase[f.value]} />
            </Col>
          ),
        )}
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <PurchaseInformationDetail
            title={'Impuestos'}
            value={totalTax}
            type={'money'}
          />
        </Col>
      </Row>
    </Card>
  )

  const detailItems =
    purchaseItems.length > 0 ? (
      <Row style={{ overflowY: 'auto', height: '60vh' }}>
        {purchaseItems.map(item => (
          <Col xl={12} key={item.warehouseId}>
            <PurchaseDetailItem
              item={item}
              seeMoneyValues={viewMonetaryBalances}
              canSeeInventory={canSeeInventory}
              toSeeInventory={(detail, serie) =>
                setDetailToSee({
                  show: true,
                  readDetail: true,
                  productId: detail.productId,
                  serie,
                })
              }
              toSeeSeries={detail =>
                setDetailToSee({
                  show: false,
                  showSerie: true,
                  series: detail.series,
                  itemName: detail.productName,
                })
              }
            />
          </Col>
        ))}
      </Row>
    ) : (
      <Card white title={'Ítems'} />
    )

  return (
    <div>
      <Modal show={showPrincipalModal} centered size={'xl'} onHide={() => onClose()}>
        <Modal.Header closeButton={!loadingUpdateStatus}>
          <Modal.Title>
            {loadingGetPurchase
              ? 'Cargando...'
              : `${purchase.isPurchase ? 'Compra' : 'Gasto'}: ${purchase.invoice}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            <Col xl={12}>
              <Loading show={loadingGetPurchase} />
            </Col>
            <Col xl={4} lg={5} md={6} sm={12} xs={12}>
              {detailInformation}
            </Col>
            <Col xl={8} lg={7} md={6} sm={12} xs={12}>
              {detailItems}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {(purchase.status === PurchaseStatusEnum.INGRESADA ||
              purchase.status === PurchaseStatusEnum.PENDIENTE_INVENTARIAR) && (
              <Button
                color={'secondary'}
                onClick={onCancel}
                disabled={getDisabledValue(PurchaseStatusEnum.CANCELADA)}
                loading={getLoadingValue(PurchaseStatusEnum.CANCELADA)}>
                Rechazar
              </Button>
            )}

            {(purchase.status === PurchaseStatusEnum.INGRESADA ||
              (purchase.status === PurchaseStatusEnum.CPP &&
                havePermissionInPayments)) && (
              <Button
                disabled={getDisabledValue(PurchaseStatusEnum.COMPLETADA)}
                loading={getLoadingValue(PurchaseStatusEnum.COMPLETADA)}
                onClick={onApprove}>
                {purchase.status === PurchaseStatusEnum.INGRESADA ? 'Aprobar' : 'Abonar'}
              </Button>
            )}

            {purchase.status === PurchaseStatusEnum.PENDIENTE_INVENTARIAR && (
              <Button
                disabled={getDisabledValue(PurchaseStatusEnum.PENDIENTE_INVENTARIAR)}
                loading={getLoadingValue(PurchaseStatusEnum.PENDIENTE_INVENTARIAR)}
                onClick={() => setShowInventoryToValidate(true)}>
                Validar inventario
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <PurchaseEditInformation
        id={purchaseId}
        show={editPurchaseInformation}
        purchase={purchase}
        onHide={(update: boolean) => {
          setEditPurchaseInformation(false)
          if (update) setUp()
        }}
      />

      <PurchaseNullify
        modal
        show={detailToSee.show}
        read={detailToSee.readDetail}
        id={purchaseId}
        serie={detailToSee.serie}
        product={detailToSee.productId}
        onHide={update => {
          setDetailToSee({ ...detailToSee, show: false, readDetail: false })
          if (update) setUp()
        }}
      />

      <DetailPayments onRefresh={() => setUp()} />

      <InventoryPurchase
        modal
        show={showInventoryToValidate}
        data={purchaseDetail}
        onHide={() => setShowInventoryToValidate(false)}
        onSave={onValidateInventory}
      />

      <PurchaseDetailItemSeries
        show={detailToSee.showSerie && !detailToSee.show}
        canSeeInventory={canSeeInventory}
        series={detailToSee.series}
        itemName={detailToSee.itemName}
        onHide={() => setDetailToSee({})}
        toSeeInventory={serie =>
          setDetailToSee({ ...detailToSee, readDetail: true, show: true, serie: serie })
        }
      />

      <ProvidersAdvances
        title={
          purchase?.status === PurchaseStatusEnum.INGRESADA ? 'Confirmación' : 'Abonar'
        }
        show={showCPPPayment}
        id={purchase?.providerId}
        name={purchase?.provider}
        nit={purchase?.nit}
        paymentCash={canPaymentWithCash}
        paymentBank={canPaymentWithBanks}
        paymentProvider={canPaymentWithProviderBalance}
        defaultUser={purchase?.createdBy}
        canSelectMySelf
        skipPayments={
          purchase?.status === PurchaseStatusEnum.INGRESADA && canConfirmWithoutPayments
        }
        max={purchase?.balance || purchase?.total}
        getPayments={onConfirm}
        onClose={() => setShowCPPPayment(false)}
      />

      <PurchaseConfirm
        show={paymentRequest.show}
        isPurchase={purchase?.isPurchase}
        purchaseId={purchaseId}
        invoice={purchase?.invoice}
        request={paymentRequest}
        details={purchaseDetail}
        onHide={() => {
          setFlags({ ...flags, executeExternalAction: true })
          setPaymentRequest({})
          setUp()
        }}
      />
    </div>
  )
}
export default PurchaseDetail
