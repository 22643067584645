import { executeAction } from './global.actions'
import PublicInvoicesService from 'src/services/public-invoices.service'

export const actionTypes = {
  GET_PUBLIC_INVOICES: 'GET_PUBLIC_INVOICES',
}

export const getPublicDTE = (uuid: string) => dispatch => {
  const process = async () => ({
    payload: await PublicInvoicesService.getPublicDTE(uuid),
  })
  dispatch(executeAction(actionTypes.GET_PUBLIC_INVOICES, process))
}
