import React, { useState, useEffect, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import Button from 'src/components/buttons/Button'
import FormText from 'src/components/inputs/FormTextField/FormText'
import Select from 'src/components/inputs/Select/CustomSelect'
import IdentificationField from 'src/components/IdentificationField/IdentificationField'
import Switch from 'src/components/inputs/Switch/SwitchV2'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { getProviders } from 'src/actions/producer.actions'
import { selectAllProviders } from 'src/selectors/producer.selector'

import { selectCompanyCountry } from 'src/selectors/company.selector'

import { actionTypes, onUpdateOrderPurchase } from 'src/actions/purchase.actions'

import { isAllowed } from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

import {
  getPurchaseProviderObj,
  getPurchasePayments,
  getPurchaseDefaultIdentification,
} from 'src/content/Purchase/Detail/PurchaseDetail/getPurchaseFunctions'

import { payments, PurchaseStatusEnum } from 'src/enums/purchaseEnums'
import { Country } from 'src/enums/countries'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import { expensePermissions, purchasePermissions } from 'src/enums/permissions'

interface IProps {
  show: boolean
  id: number
  purchase: IPurchase
  onHide: (update: boolean) => void
}

/** Purchase edit basic information modal
 * @param {boolean} show Indicates whether the modal should be shown
 * @param {number} id ID of purchase to edit
 * @param {IPurchase} purchase Purchase obj
 * @param {function} onHide Close modal
 * **/
const PurchaseEditInformation = ({
  show,
  id,
  purchase,
  onHide,
}: IProps): ReactElement => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const providers = useSelector(selectAllProviders)

  const loadingUpdate: boolean = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_PURCHASE_INFORMATION])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrors([actionTypes.UPDATE_PURCHASE_INFORMATION])(state),
  )

  // PERMISSIONS
  const canChangeRequirePayments = useSelector(state =>
    isAllowed(state, [
      purchase?.isPurchase
        ? purchasePermissions.canChangeRequirePayments
        : expensePermissions.canChangeRequirePayments,
    ]),
  )

  const canChangeAddInventory = useSelector(state =>
    isAllowed(state, [purchasePermissions.canChangeAddInventory]),
  )

  const statusCanUpdateAll = [
    PurchaseStatusEnum.INGRESADA,
    PurchaseStatusEnum.PENDIENTE_INVENTARIAR,
  ]
  const canUpdateAll = statusCanUpdateAll.includes(purchase?.status)

  const paymentTypes = getPurchasePayments(purchase?.isPurchase)

  const [flags, setFlags] = useState({ update: false })

  const [paymentType, setPaymentType] = useState<ISelectNumber>({})
  const [nit, setNit] = useState<string>('CF')
  const [provider, setProvider] = useState<ISelectConsultNit>({})
  const [serie, setSerie] = useState<string>(null)
  const [invoice, setInvoice] = useState<string>(null)
  const [removeInventory, setRemoveInventory] = useState<boolean>(false)
  const [addPayments, setAddPayments] = useState<boolean>(false)

  useEffect(() => {
    if (!show) return
    if (providers.length <= 0) dispatch(getProviders())

    setPaymentType(payments[purchase.paymentType])
    setNit(purchase.nit)
    setProvider(
      getPurchaseProviderObj(purchase.providerId, purchase.provider, purchase.nit),
    )
    setSerie(purchase.serie)
    setInvoice(purchase.invoice)
    setRemoveInventory(
      purchase.isPurchase ? !purchase.removeInventory : purchase.removeInventory,
    )
    setAddPayments(purchase.addPayments)
  }, [show])

  useEffect(() => {
    if (loadingUpdate) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })
      if (hasErrorUpdate) dispatch(showAlert({ ...handlerError(hasErrorUpdate.message) }))
      else onHide(true)
    }
  }, [loadingUpdate])

  const onSave = () => {
    const request = {
      paymentType: paymentType?.value,
      invoice,
      serie,
      nit,
      providerName: provider?.label,
      providerId: provider?.value,
    }
    dispatch(
      onUpdateOrderPurchase(
        {
          purchase: request,
          removeInventory: purchase.isPurchase ? !removeInventory : removeInventory,
          addPayments,
        },
        '',
        id,
        3,
      ),
    )
  }

  const identificationType: number = getPurchaseDefaultIdentification(country?.id)

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <Modal.Header closeButton={!loadingUpdate}>
        <Modal.Title>Edición: {purchase.invoice}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'custom-modal-body'}>
        <Row>
          <Col xl={12}>
            <Row>
              <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                <IdentificationField
                  value={nit}
                  onChange={newNit => setNit(`${newNit}`)}
                  disabled={loadingUpdate || !canUpdateAll}
                  identificationTypeId={identificationType}
                  onSearch={value =>
                    // @ts-ignore
                    setProvider(getPurchaseProviderObj(null, value.clientName, value.NIT))
                  }
                  onFound={option =>
                    setProvider(
                      // @ts-ignore
                      getPurchaseProviderObj(option.id, option.name, option.nit),
                    )
                  }
                  options={providers}
                />
              </Col>
              <Col xl={7} lg={7} md={6} sm={12} xs={12}>
                <Select
                  options={providers}
                  label={'Proveedor'}
                  value={provider}
                  disabled={loadingUpdate || !canUpdateAll}
                  onChange={newProvider => {
                    setProvider(newProvider)
                    setNit(newProvider.nit)
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col xl={12}>
            <FormText
              value={serie}
              label={'NO. Serie'}
              changeValue={setSerie}
              disabled={loadingUpdate}
            />
          </Col>

          <Col xl={12}>
            <FormText
              value={invoice}
              label={'Factura'}
              changeValue={setInvoice}
              disabled={loadingUpdate}
            />
          </Col>
          {((purchase.isPurchase && canChangeAddInventory) ||
            purchase.warehouseId !== undefined) &&
            canUpdateAll && (
              <Col xl={12}>
                <Switch
                  label={
                    purchase.isPurchase ? 'Generar inventario' : 'Reducir inventario'
                  }
                  info={
                    purchase.isPurchase
                      ? 'Al confirmarse la compra ingresará inventario en la bodega seleccionada.'
                      : 'Al activar esta opción removerá el inventario en existencia de los ítems seleccionados en el gasto.'
                  }
                  checked={removeInventory}
                  onChange={setRemoveInventory}
                />
              </Col>
            )}

          {canChangeRequirePayments && canUpdateAll && (
            <Col xl={12}>
              <Switch
                label={'Requerir pagos'}
                info={
                  'Para confirmar se requerirá ingresar pagos o dejarla como cuenta por pagar.'
                }
                checked={addPayments}
                onChange={setAddPayments}
              />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={loadingUpdate}
            color={'secondary'}
            icon={faWindowClose}
            onClick={() => onHide(false)}>
            Cerrar
          </Button>
          <Button icon={faSave} onClick={onSave} loading={loadingUpdate}>
            Actualizar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default PurchaseEditInformation
