import { $http } from './http'
import { saveAs } from 'file-saver'

/**
 * Contiene los servicios de inventario
 */
class InventoryService {
  getChangeRequests = async params => {
    let queryParams = '?'
    Object.keys(params).forEach(k => {
      if (params[k] !== null && params[k] !== undefined) {
        queryParams += `${k}=${params[k]}&`
      }
    })

    const response = await $http.get('/inventory/request'.concat(queryParams))
    return response.data
  }

  getReportRequest = async (start, end, user1, user2) => {
    const response = await $http.get('/inventory/request/report', {
      params: { start, end, user1, user2 },
    })
    return response.data
  }

  getReportSum = async (start, end, type, factor) => {
    const response = await $http.get('/inventory/request/sum', {
      params: { start, end, type, factor },
    })
    return response.data
  }

  onCreateInventoryRequest = async (request, all) => {
    const response = await $http.post('/inventory/request', request, {
      params: { all },
    })
    return response.data
  }

  getChangeComplete = async requestId => {
    const response = await $http.get(`/inventory/request/${requestId}`)
    return response.data
  }

  onUpdateInventoryRequest = async (requestId, status) => {
    const response = await $http.put(`/inventory/request/${requestId}?status=${status}`)
    return response.data
  }

  getReport = async (start, end, warehouses, users, categories, type, advances) => {
    const response = await $http.put(
      `/cotizap/reports/inventory`,
      { warehouses, users, categories },
      { params: { start, end, type, advances } },
    )
    return response.data
  }

  getReportItem = async (
    start,
    end,
    warehouses,
    users,
    categories,
    products,
    type,
    advances,
  ) => {
    const response = await $http.put(
      `/cotizap/reports/inventory/item/`,
      { warehouses, users, categories, products },
      { params: { start, end, type, advances } },
    )
    return response.data
  }

  onReturnInventoryByOfferZone = async offerId => {
    const response = await $http.post(`/inventory/transfers/return/${offerId}`)
    return response.data
  }

  assignWarehouseLocations = async (request, params) => {
    const response = await $http.post('/inventory/location', request, { params })
    return response.data
  }

  loadInventoryLocations = async (warehouseId, locations, currentIndex) => {
    const response = await $http.post(
      `/inventory/csv/location/${warehouseId}?index=${currentIndex}`,
      locations,
    )
    return response.data
  }

  getCSVLocations = async (warehouseId, ids, all) => {
    const response = await $http.put(
      `/inventory/export/csv/${warehouseId}?all=${all}`,
      ids,
    )
    let text = response.data
      .split('\n')
      .map(line => line.split(/\s+/).join(' '))
      .join('\n')

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += text

    const uri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', uri)
    link.setAttribute('download', 'update_locations.csv')
    document.body.appendChild(link)
    link.click()

    return response.data
  }

  /**
   * Servicio de carga masiva de productos a Ingreso de inventario
   * @param {Array<Object>} products Lista de productos provenientes del CSV {SKU,cantidad, costo unitario}
   * @param {Number} warehouseId     Id de bodega
   * @returns Object Response service
   */
  loadProductsProduction = async (products, warehouseId) => {
    const response = await $http.post(
      `/inventory/csv/products/production/${warehouseId}`,
      products,
    )
    return response.data
  }

  getPendingMissingRequestExcel = async (warehouseId, params) => {
    const response = await $http.get(`/inventory/dispatch/${warehouseId}/pending/excel`, {
      params,
      responseType: 'blob',
    })
    const name = 'Solicitudes de despacho.xlsx'
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }

  dispatchPendingInventory = async (warehouseId, products) => {
    const response = await $http.post(`/inventory/dispatch/pending/multiple`, products, {
      params: { warehouseId },
    })
    return response.data
  }

  loadInventory = async (warehouseId, items, currentIndex) => {
    const response = await $http.post(
      `/inventory/csv/${warehouseId}/?index=${currentIndex}`,
      items,
    )
    return response.data
  }

  getCSVInventoryProducts = async () => {
    const response = await $http.get(`/inventory/products/csv`)
    let text = response.data
      .split('\n')
      .map(line => line.split(/\s+/).join(' '))
      .join('\n')

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += text

    const uri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', uri)
    link.setAttribute('download', 'products_inventory.csv')
    document.body.appendChild(link)
    link.click()

    return response.data
  }

  nullifyInventoryRequest = async requestId => {
    const response = await $http.put(`/inventory/request/nullify/${requestId}`)
    return response.data
  }

  /**
   * Service to get the inventory change request excel
   * @param params            Request params filters
   * @returns {Promise<void>} Excel file
   */
  getInventoryChangeRequestExcel = async params => {
    const response = await $http.get(`/inventory/request/excel`, {
      params,
      responseType: 'blob',
    })
    const name =
      (params.type === 1 ? 'ingreso-de-inventario' : 'reducciones-de-inventario') +
      '.xlsx'
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }
}

const services = new InventoryService()

export default services
