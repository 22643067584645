import React, { useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Overlay, Row, Stack } from 'react-bootstrap'
import Button from 'src/components/buttons/Button'
import RouteMapping from 'src/enums/routeV2BannerEnum'
import Paragraph from 'src/components/Paragraph/Paragraph'

interface VersionNotificationProps {
  currentModuleRoute?: string
}

/**
 * Get the base URL
 */
const getBaseUrl = (): string => {
  const { protocol, host } = window.location // Get protocol and host from window.location
  return `${protocol}//${host}` // Construct the base URL
}
/**
 * VersionNotification component  that shows a notification banner for the new version
 * @param currentModuleRoute
 * @constructor
 */ const VersionNotification = ({ currentModuleRoute }: VersionNotificationProps) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [topPosition, setTopPosition] = useState(190)
  const targetRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const currentTime = Date.now()
    const storedTimestamp = localStorage.getItem('bannerDismissedUntil')

    if (!storedTimestamp || currentTime > parseInt(storedTimestamp, 10)) {
      setShowOverlay(true)
    }
  }, [])

  const goToNewVersion = async () => {
    const baseUrl = getBaseUrl() // Dynamically get the base URL
    const newRoute = RouteMapping[currentModuleRoute || 'Home']
    window.location.href = `${baseUrl}/${newRoute}` // Redirect to the new version
  }

  useEffect(() => {
    const updateTopPosition = () => {
      const newPosition =
        window.innerWidth <= 360
          ? 300
          : window.innerWidth <= 575
          ? 280
          : window.innerWidth <= 720
          ? 300
          : 190

      setTopPosition(newPosition)
    }

    window.addEventListener('resize', updateTopPosition)
    updateTopPosition()

    return () => {
      window.removeEventListener('resize', updateTopPosition)
    }
  }, [])

  const handleClose = () => {
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000
    const dismissUntil = new Date().getTime() + oneWeekInMilliseconds
    localStorage.setItem('bannerDismissedUntil', dismissUntil.toString())

    setShowOverlay(false)
  }
  return (
    <Container data-testid="version-notification">
      <Row>
        <Col xl={6} lg={6} xxl={6} sm={8} xs={12} md={6}>
          <div
            ref={targetRef}
            style={{
              position: 'absolute',

              top: topPosition,
              right: 10,
            }}></div>
        </Col>
      </Row>
      <Overlay target={targetRef.current} show={showOverlay} placement="top">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <Row>
            <Col
              xl={6}
              md={8}
              lg={6}
              xxl={6}
              sm={8}
              xs={12}
              {...props}
              style={{
                backgroundColor: 'hsl(319, 38%, 52%)',
                padding: '2px',
                height: 'auto',
                borderRadius: 5,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                zIndex: 1050,
                ...props.style,
              }}>
              <Alert
                variant="info"
                style={{
                  height: 'auto',

                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                  color: '#000000',
                }}>
                {' '}
                <Alert.Heading>
                  <Paragraph size={'extraBig'} style={{ color: '#FFFF' }} bold={true}>
                    ¡Nueva Versión 2.0 Disponible!
                  </Paragraph>
                </Alert.Heading>
                <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                  <Paragraph
                    size={'medium'}
                    style={{ color: '#FFFF', textAlign: 'justify' }}
                    bold={false}
                    dim={false}>
                    Hemos lanzado la versión 2.0 de este módulo con mejoras y nuevas
                    funcionalidades para optimizar tu experiencia. ¿Qué te gustaría hacer?
                  </Paragraph>
                  <br />
                </Col>
                <br />
                <br />
                <Stack direction={'vertical'}>
                  <Button
                    className={'color-accent'}
                    onClick={goToNewVersion}
                    style={{
                      fontWeight: 600,
                    }}>
                    Acceder a la Versión 2.0
                  </Button>
                  <Button
                    onClick={handleClose}
                    className={'color-accent'}
                    style={{
                      fontWeight: 600,
                    }}>
                    Recordarme más tarde
                  </Button>
                </Stack>
              </Alert>
            </Col>
          </Row>
        )}
      </Overlay>
    </Container>
  )
}

export default VersionNotification
