import React, { useEffect, useState } from 'react'
import { graphs as colorArray } from 'src/utils/graphs'
import { BarChart as Bar } from './Bar'
import { Col, Row } from 'react-bootstrap'
import { Line } from './Line'
import { Donut } from './Donut'
import { Pie } from './Pie'
import { ChartType } from 'src/enums/ChartType'
import { toMoney } from 'src/utils/utilities'

interface Props {
  data: IDataGraph
  title: string
  currencyValues?: boolean
  withTable?: boolean
  type: number
  yAxisName?: string
  height?: number
}

/**
 * Component to display the different types of graphs.
 * @component
 * @param {IDataGraph} data List of items to display
 * @param {string} title Chart title
 * @param {boolean} currencyValues If you want to show the value of the tooltip in currency
 * @param {boolean} withTable If want to display the data table
 * @param {number} type Type of chart to display
 * @param {string} yAxisName Name of the Y axis, substitute the units of that axis (for Bar Chart)
 * @param {number} height Chart height
 * @returns
 */
const ChartComponent = ({
  data,
  title,
  currencyValues,
  withTable,
  type,
  yAxisName,
  height,
}: Props) => {
  const { labels, datasets } = data
  const [dataGraph, setData] = useState<IDataGraph>({ labels: [], datasets: [] })

  useEffect(() => {
    setData(Object.assign(data))
    filledValues([])
  }, [data])

  const oneDataset = data.datasets.length === 1

  const tooltip: ITooltipGraph = {
    backgroundColor: '#FFFFFF',
    titleFontColor: '#525252',
    borderColor: '#C1BDBC',
    titleFontFamily: 'Montserrat',
    borderWidth: 2,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    shadowBlur: 10,
    shadowColor: '#2E4045',
    callbacks: {
      title: function (tooltipItems: ITooltipItem[], data: IDataGraph) {
        const index = tooltipItems[0].index
        return data.labels[index]
      },
      label: function (tooltipItem: ITooltipItem, data: IDataGraph) {
        const index = tooltipItem.index
        return (
          title +
          (currencyValues
            ? ' ' + toMoney(data.datasets[0].data[index])
            : ' ' + Number(data.datasets[0].data[index]).toFixed(0) + ' unidades')
        )
      },
      labelTextColor: function () {
        return '#525252'
      },
    },
  }

  /**
   * Filter to hide data according to the index in hiddenData
   * @param list Array to filter
   * @param hiddenData Indexes hidden
   * @returns
   */
  const filterData = (list, hiddenData: number[]) => {
    return withTable ? list.filter((i, index) => !hiddenData.includes(index)) : list
  }

  /**
   * Updates the data that renders the graph, with the new values.
   * @param hiddenData Indexes hidden
   */
  const filledValues = (hiddenData: number[]) => {
    if (!oneDataset) {
      setData({
        labels: labels,
        datasets: datasets.map((dataset, index) => ({
          ...dataset,
          hidden: hiddenData.includes(index),
        })),
      })
    } else {
      const colors = filterData(colorArray, hiddenData)
      setData({
        labels: filterData(labels, hiddenData),
        datasets: datasets.map((dataset, index) => ({
          ...dataset,
          data: filterData(dataset.data, hiddenData),
          //pointBackgroundColor: type === ChartType.LINE && withTable && colors.map(c => c + '66') ,
          backgroundColor:
            type === ChartType.LINE ? colorArray[0] + '66' : colors.map(c => c + '66'),
          borderColor: type === ChartType.LINE ? colorArray[0] : colors,
          borderWidth: type === ChartType.LINE ? 2 : 1,
        })),
      })
    }
  }

  return (
    <Row>
      <Col lg={withTable ? 7 : 12}>
        <div className="mt-5">
          {(type === ChartType.BAR || type === ChartType.HORIZONTAL_BAR) && (
            <Bar
              height={height}
              data={dataGraph}
              tooltips={tooltip}
              type={type}
              yAxisName={yAxisName}
            />
          )}
          {type === ChartType.LINE && (
            <Line height={height} data={dataGraph} tooltips={tooltip} />
          )}
          {type === ChartType.DONUT && (
            <Donut height={height} data={dataGraph} tooltips={tooltip} />
          )}
          {type === ChartType.PIE && (
            <Pie height={height} data={dataGraph} tooltips={tooltip} />
          )}
        </div>
      </Col>
    </Row>
  )
}

export const Chart = React.memo(ChartComponent)
