import './Products.scss'

import React, { useEffect, useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  Title,
  Icon as ButtonIcon,
  SelectedDates,
  Button,
  Folder,
  Select,
} from 'src/components'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectUsers } from 'src/selectors/modules.selector'
import { getAllPOSByUser } from 'src/actions/restaurant.actions'
import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'
import { faFileExcel, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { Chart } from 'src/components/Graph/Chart'
import { quantityOptions, sortOptions } from 'src/utils/graphs'
import { ChartType } from 'src/enums/ChartType'
import {
  actionTypes as actionReport,
  getSaleReportByItems,
  getSaleReportByItemsExcel,
} from 'src/actions/report.actions'
import { selectItemSaleReport } from 'src/selectors/report.selector'
import { handlerError, hasErrorsSelector } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { Dropdown } from '../../../components'
import { ChartTable } from 'src/components/Graph/ChartTable'

const today = new Date()

/**
 * Item sales report screen
 * @component
 */
export const ItemSale = () => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const users: ISelect[] = useSelector(selectUsers)
  const warehouses: IWarehouse[] = useSelector(selectWarehouseLite)
  const categorization: ICategorization = useSelector(selectAllCategorizations)
  const pointOfSales = useSelector(selectAllPOSUser)
  const report: ISaleReport[] = useSelector(selectItemSaleReport)

  const reportLoading = useSelector(state =>
    loadingSelector([actionReport.GET_SALE_REPORT_BY_ITEMS])(state),
  )
  const excelLoading = useSelector(state =>
    loadingSelector([actionReport.GET_SALE_REPORT_BY_ITEMS_EXCEL])(state),
  )
  const excelHasError = useSelector(state =>
    hasErrorsSelector([actionReport.GET_SALE_REPORT_BY_ITEMS_EXCEL])(state),
  )

  const defaultFilter: ISelect = {
    value: 0,
    label: 'Todos',
  }
  const [showCategorizations, setShowCategorizations] = useState(false)
  const [filters, setFilters] = useState({
    fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
    toDate: today,
    seller: defaultFilter,
    deliverer: defaultFilter,
    selectedCategorizations: [],
    selectedPos: [],
    selectedWarehouses: [],
  })
  const [filterGraph, setFilterGraph] = useState({
    quantityProducts: quantityOptions[0],
    sortProducts: sortOptions[0],
    typeProducts: { value: 1, label: 'Monto' },
  })
  const [dataGraph, setDataGraph] = useState<IDataGraph>({
    labels: ['y'],
    datasets: [
      {
        label: 'x',
        data: [0],
        backgroundColor: '#FF6384',
      },
    ],
  })
  const [action, setAction] = useState(false)

  useEffect(() => {
    dispatch(getAllPOSByUser(user.id))
    dispatch(getWarehouseLite({ all: true }))
    dispatch(getAllCategorizations(16))
    dispatch(getUsersChildrenByModule(1000, true))
    setUp()
  }, [])

  useEffect(() => {
    if (!excelLoading && excelHasError) {
      dispatch(
        showAlert({
          ...handlerError('Se ha producido un error al generar el reporte'),
        }),
      )
    }
  }, [excelLoading])

  useEffect(() => {
    if (reportLoading) setAction(true)
    else if (action) {
      setAction(false)
      setDataGraph(filterData())
    }
  }, [reportLoading])

  useEffect(() => {
    setDataGraph(filterData())
  }, [JSON.stringify(filterGraph)])

  /**
   * Filter the data to render in graph.
   * @returns IDataGraph
   */
  const filterData = (): IDataGraph => {
    const { quantityProducts, sortProducts, typeProducts } = filterGraph

    const param =
      typeProducts.value === 1
        ? 'total'
        : typeProducts.value === 2
        ? 'discount'
        : 'quantity'

    const dataFiltered: ISaleReport[] = report
      .filter(f => f[param] > 0)
      .sort((a, b) =>
        sortProducts.value === 1 ? b[param] - a[param] : a[param] - b[param],
      )
      .slice(0, quantityProducts.value)

    return {
      labels: dataFiltered.map((p: ISaleReport) => p.name),
      datasets: [
        {
          label: 'Productos',
          data: dataFiltered.map((p: ISaleReport) => p[param]),
        },
      ],
    }
  }

  /**
   * Function that is responsible for obtaining the data of the report.
   * @function
   */
  const setUp = () => {
    const {
      seller,
      deliverer,
      selectedPos,
      selectedCategorizations,
      selectedWarehouses,
      toDate,
      fromDate,
    } = filters

    const params = {
      startDate: fromDate.valueOf(),
      endDate: toDate.valueOf(),
      deliverer: deliverer.value,
      seller: seller.value,
    }
    const request = {
      categories: selectedCategorizations.map(p => p.id),
      pos: selectedPos.map(p => p.id),
      warehouses: selectedWarehouses.map(p => p.id),
    }
    dispatch(getSaleReportByItems(params, request))
  }

  /**
   * Function that is responsible for obtaining the data of the report and convert in excel file.
   * @function
   */
  const getExcelReport = (segmented = false) => {
    const {
      deliverer,
      fromDate,
      selectedCategorizations,
      selectedPos,
      selectedWarehouses,
      seller,
      toDate,
    } = filters

    const params = {
      startDate: fromDate.valueOf(),
      endDate: toDate.valueOf(),
      deliverer: deliverer.value,
      seller: seller.value,
      segmented,
    }
    const request = {
      categories: selectedCategorizations.map(p => p.id),
      warehouses: selectedWarehouses.map(p => p.id),
      pos: selectedPos.map(p => p.id),
    }

    dispatch(getSaleReportByItemsExcel(params, request))
  }

  /**
   * Gets the report filters
   * @returns JSX.Element
   */
  const getFilters = () => {
    const {
      seller,
      deliverer,
      selectedCategorizations,
      selectedPos,
      selectedWarehouses,
    } = filters
    return (
      <>
        <Col xl={6} lg={6} md={6} sm={12}>
          <Select
            disabled={reportLoading}
            label={'Creado Por'}
            name={'seller'}
            value={seller}
            options={[defaultFilter, ...users]}
            onChange={(item: ISelect) => setFilters({ ...filters, seller: item })}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <Select
            disabled={reportLoading}
            label={'Entregado Por'}
            name={'deliverer'}
            value={deliverer}
            options={[defaultFilter, ...users]}
            onChange={(item: ISelect) => setFilters({ ...filters, deliverer: item })}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={'column'}>
            <Select
              disabled={reportLoading}
              label={'Puntos de venta'}
              options={pointOfSales.filter(
                x => selectedPos.findIndex(y => y.id === x.id) === -1,
              )}
              value={null}
              placeholder={'Seleccione un punto de venta'}
              onChange={e => {
                setFilters({ ...filters, selectedPos: [...selectedPos, e] })
              }}
            />
            <Row>
              {filters.selectedPos.map((p, i) => (
                <div className={'user-tag product-t ml-2'} key={i}>
                  <label className={'label-user-tag'}>{p.label}</label>
                  <ButtonIcon
                    className={'delete-user-tag d-product-t'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        selectedPos: selectedPos.filter(f => f.id !== p.id),
                      })
                    }
                  />
                </div>
              ))}
            </Row>
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={'column'}>
            <Select
              disabled={reportLoading}
              label={'Bodegas'}
              options={warehouses.filter(
                x => selectedWarehouses.findIndex(y => y.id === x.id) === -1,
              )}
              placeholder={'Seleccione una bodega'}
              value={null}
              onChange={(warehouse: IWarehouse) => {
                setFilters({
                  ...filters,
                  selectedWarehouses: [...selectedWarehouses, warehouse],
                })
              }}
            />
            <Row className={'pl-1'}>
              {selectedWarehouses.map((p: IWarehouse, i: number) => (
                <div className={'user-tag warehouse-t ml-2'} key={i}>
                  <label className={'label-user-tag'}>{p.label}</label>
                  <ButtonIcon
                    className={'delete-user-tag d-warehouse-t'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        selectedWarehouses: selectedWarehouses.filter(f => f.id !== p.id),
                      })
                    }
                  />
                </div>
              ))}
            </Row>
          </div>
        </Col>
        <Col xl={12}>
          <div className={'space-between'}>
            <Button
              disabled={reportLoading}
              color={'primary'}
              onClick={() => setShowCategorizations(true)}>
              Filtrar por categorías
            </Button>
          </div>
        </Col>
        <Col xl={12}>
          <Row className={'pl-1'}>
            {selectedCategorizations.map((p: ICategorization, i) => (
              <div className={'user-tag product-t ml-2'} key={i}>
                <label className={'label-user-tag'}>{p.name}</label>
                <ButtonIcon
                  className={'delete-user-tag d-product-t'}
                  icon={faTimes}
                  tooltip={'Quitar'}
                  color={'white'}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      selectedCategorizations: selectedCategorizations.filter(
                        f => f.id !== p.id,
                      ),
                    })
                  }
                />
              </div>
            ))}
          </Row>
        </Col>
      </>
    )
  }

  /**
   * Gets the report chart and filters
   * @returns JSX.Element
   */
  const getChart = () => {
    const { quantityProducts, sortProducts, typeProducts } = filterGraph
    return (
      <Card title={'Visualizar'} white>
        <Row>
          <Col xl={12}>
            {reportLoading && (
              <Row>
                <Col>
                  <div className={'pb-custom'}>
                    <ProgressBar
                      label="Cargando"
                      animated
                      now={100}
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col xl={4} lg={4} md={4} sm={6} xs={12}>
            <Select
              label={'Cantidad de Productos'}
              name={'product_quantity'}
              value={quantityProducts}
              options={quantityOptions}
              onChange={option =>
                setFilterGraph({ ...filterGraph, quantityProducts: option })
              }
            />
          </Col>
          <Col xl={4} lg={4} md={4} sm={6} xs={12}>
            <Select
              label={'Ordenar'}
              name={'sort'}
              value={sortProducts}
              options={sortOptions}
              onChange={option =>
                setFilterGraph({ ...filterGraph, sortProducts: option })
              }
            />
          </Col>
          <Col xl={4} lg={4} md={4} sm={6} xs={12}>
            <Select
              label={'Tipo'}
              name={'type'}
              value={typeProducts}
              options={[
                { value: 1, label: 'Monto' },
                { value: 2, label: 'Descuentos' },
                { value: 3, label: 'Unidades' },
              ]}
              onChange={option =>
                setFilterGraph({ ...filterGraph, typeProducts: option })
              }
            />
          </Col>

          {!reportLoading && (
            <Col xl={12}>
              <Row>
                <Col xl={8} lg={8} md={12}>
                  <Chart
                    data={dataGraph}
                    currencyValues={typeProducts.value !== 3}
                    height={600}
                    title={typeProducts.label}
                    type={ChartType.HORIZONTAL_BAR}
                    yAxisName="Productos"
                  />
                </Col>
                <Col xl={4} lg={4} md={12}>
                  <ChartTable
                    title={typeProducts.label}
                    currency={typeProducts.value !== 3}
                    items={report?.map(r => ({
                      id: r.id,
                      name: r.name,
                      amount:
                        typeProducts?.value === 1
                          ? r.total
                          : typeProducts?.value === 2
                          ? r.discount
                          : r.quantity,
                    }))}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card>
    )
  }

  return (
    <div className={'text-center'}>
      <Title title={'Reporte de ventas por ítems'} />
      <Card
        title={'filtros'}
        white
        button={
          <Row className={'container-buttons'} style={{ alignItems: 'center' }}>
            <Dropdown
              style={{ textTransform: 'none' }}
              tooltip={'Exportar'}
              loading={reportLoading || excelLoading}
              items={[
                {
                  title: 'Descargar reporte',
                  tooltip: 'Exporta un excel con los filtros aplicados',
                  action: () => getExcelReport(),
                  icon: faFileExcel,
                  iconColor: 'green',
                  show: true,
                },
                {
                  title: 'Descargar reporte segmentado',
                  tooltip:
                    'Exporta un excel con los filtros aplicados segmentado por vendedor',
                  action: () => getExcelReport(true),
                  icon: faFileExcel,
                  iconColor: 'green',
                  show: true,
                },
              ]}
            />
          </Row>
        }>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <SelectedDates
              nonExecute
              dates={{
                dateFrom: filters.fromDate,
                dateTo: filters.toDate,
              }}
              onDateChange={(fromDate, toDate) =>
                setFilters({ ...filters, fromDate, toDate })
              }
            />
          </Col>

          {getFilters()}
        </Row>
        <Row style={{ float: 'right' }}>
          <Button
            loading={reportLoading}
            color={'accent'}
            icon={faSearch}
            onClick={() => {
              setUp()
            }}>
            Aplicar Filtros
          </Button>
        </Row>
      </Card>
      {getChart()}

      {/* @ts-expect-error javascript */}
      <Folder
        noMessage
        list={filters.selectedCategorizations.map(d => d.id)}
        onHide={() => setShowCategorizations(false)}
        onAssign={item => {
          if (filters.selectedCategorizations.findIndex(p => p.id === item.id) === -1) {
            filters.selectedCategorizations.push(item)
            setFilters({ ...filters })
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorizations}
      />
    </div>
  )
}
