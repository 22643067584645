import { extract, successState } from '../actions/global.actions'
import { actionTypes } from 'src/actions/public-invoices.action'

const dteDefault: IPublicDocumentDTO = {
  amount: '',
  documentType: '',
  authDate: '',
  data: [],
  documents: [],
  ok: false,
}

const initialState: IPublicDocumentState = {
  dte: dteDefault,
}

const PublicInvoiceReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
) => {
  switch (type) {
    case successState(actionTypes.GET_PUBLIC_INVOICES):
      return { ...state, dte: extract(payload, dteDefault) }
    default:
      return state ?? initialState
  }
}
export default PublicInvoiceReducer
