import { $http } from './http'
import { saveAs } from 'file-saver'
import { updateTipFavorite } from '../actions/restaurant.actions'
import { request } from 'websocket'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import UtilitiesServices from 'src/services/utilities.services'
import { itemTypes } from 'src/utils/utilities'

class RestaurantService {
  createTable = async tableRestaurant => {
    const response = await $http.post(`/restaurant`, tableRestaurant)
    return response.data
  }

  deleteTable = async id => {
    const response = await $http.delete(`/restaurant/${id}`)
    return response.data
  }

  getTablesByCompany = async params => {
    const response = await $http.get(`/restaurant`, { params })
    return response.data
  }

  getTableOrder = async (id, posId) => {
    const response = await $http.get(`restaurant/order/${id}`, { params: { posId } })
    if (!response.data.posId) response.data.posId = posId

    return response.data
  }

  createTableOrder = async (request, posId, withoutInventory) => {
    /*if (haveAnyValue(request?.tableId))
      await UtilitiesServices.updateStatusConfirmationInProgress(
        itemTypes.POS,
        request.tableId,
      )*/

    const response = await $http.post(`/restaurant/order`, request, {
      params: { withoutInventory, posId },
    })
    return response.data
  }

  addProductToTableOrder = async (request, withoutInventory) => {
    const response = await $http.post(`/restaurant/order/product`, request, {
      params: { withoutInventory },
    })
    return response.data
  }

  deleteProduct = async (table, detail) => {
    const response = await $http.delete(`/restaurant/order/product/${table}/${detail}`)
    return response.data
  }

  setWaiter = async (waiterId, warehouseId, type, tableId, tableName) => {
    const response = await $http.put(
      `/restaurant/table/${tableId}`,
      {},
      { params: { waiterId, warehouseId, type, tableName } },
    )
    return response.data
  }

  getTablesStatus = async () => {
    const response = await $http.get(`/restaurant/availability`)
    return response.data
  }

  generateOrder = async (request, dontCertificate) => {
    const response = await $http.post(`/restaurant/order/payment`, request, {
      params: { dontCertificate },
    })
    return response.data
  }

  immediatelyInvoice = async (request, params) => {
    const response = await $http.post(`/restaurant/order/immediately`, request, {
      params,
    })
    return response.data
  }

  createChair = async request => {
    const response = await $http.post(`/restaurant/chair`, request)
    return response.data
  }

  deleteChair = async (tableId, chairId) => {
    const response = await $http.delete(`/restaurant/chair/${tableId}/${chairId}`)
    return response.data
  }

  getChairs = async tableId => {
    const response = await $http.get(`/restaurant/chair/${tableId}`)
    return response.data
  }

  bookATable = async request => {
    const response = await $http.post(`/restaurant/reserve`, request)
    return response.data
  }

  deleteReservation = async id => {
    const response = await $http.delete(`/restaurant/reserve/${id}`)
    return response.data
  }

  getReservedStatus = async () => {
    const response = await $http.get(`/restaurant/reserved/status`)
    return response.data
  }

  getReservationByTable = async tableId => {
    const response = await $http.get(`/restaurant/reserve/${tableId}`)
    return response.data
  }

  getAllTableOrders = async () => {
    const response = await $http.get(`/restaurant/kitchen`)
    return response.data
  }

  deleteTableOrder = async (order, returnInventory, byLink) => {
    const response = await $http.delete(`/restaurant/order/${order}`, {
      params: { returnInventory, byLink },
    })
    return response.data
  }

  printableOrder = async order => {
    const response = await $http.get(`/restaurant/print/${order}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' - Comanda - ' +
      order +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
  }

  createTip = async (percentage, params) => {
    const response = await $http.post(`/restaurant/tip`, percentage, { params })
    return response.data
  }

  updateTipFavorite = async (tipId, params) => {
    const response = await $http.put(`/restaurant/tip/favorite/${tipId}`, {}, { params })
    return response.data
  }

  deleteTip = async tipId => {
    const response = await $http.delete(`/restaurant/tip/${tipId}`)
    return response.data
  }

  getTipsAvailable = async params => {
    const response = await $http.get('/restaurant/tips', { params })
    return response.data
  }

  updateTipInTableOrder = async (tableOrderId, params) => {
    const response = await $http.put(`/restaurant/tip/${tableOrderId}`, {}, { params })
    return response.data
  }

  updateDiscountInTableOrder = async (tableOrderId, params) => {
    const response = await $http.put(
      `/restaurant/discount/${tableOrderId}`,
      {},
      { params },
    )
    return response.data
  }

  updateProductsState = async (
    orderId,
    detailIds,
    status,
    description,
    noAcceptProduction,
  ) => {
    const response = await $http.put(
      `/restaurant/product/${orderId}/${status}`,
      detailIds,
      { params: { description, noAcceptProduction } },
    )
    return response.data
  }

  getTicketPDF = async tableOrderId => {
    const response = await $http.get(`/restaurant/ticket/${tableOrderId}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileName = 'comanda-' + tableOrderId + '.pdf'
    saveAs(file, fileName)
  }

  getTicketProductionPDF = async (tableOrderId, detailIds) => {
    const response = await $http.put(
      `/restaurant/ticket/production/${tableOrderId}`,
      detailIds,
      {
        responseType: 'blob',
      },
    )

    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileName = 'produccion-' + tableOrderId + '.pdf'
    saveAs(file, fileName)
  }

  getAllPOS = async params => {
    const response = await $http.get('/restaurant/pos', { params })
    return response.data
  }

  getPosAdmins = async posId => {
    const response = await $http.get(`/restaurant/pos/admin/${posId}`)
    return response.data
  }

  isPosAdmin = async (posId, userId) => {
    const response = await $http.get(`/restaurant/pos/admin/${posId}/${userId}`)
    return response.data
  }

  addPosAdmin = async (posId, params) => {
    const response = await $http.post(`/restaurant/pos/admin/${posId}`, null, { params })
    return response.data
  }

  removePosAdmin = async (posId, params) => {
    const response = await $http.delete(`/restaurant/pos/admin/${posId}`, { params })
    return response.data
  }

  onCreatePOS = async (request, params) => {
    const response = await $http.post('/restaurant/pos', request, { params })
    return response.data
  }

  onUpdatePOS = async (posId, request, params) => {
    const response = await $http.put(`/restaurant/pos/${posId}`, request, { params })
    return response.data
  }

  updateDetailTag = async (detailId, tag, commentary) => {
    const response = await $http.put(
      `/restaurant/product/${detailId}/tag`,
      {},
      { params: { tag, commentary } },
    )
    return response.data
  }

  transferTable = async (tableId, newTableId) => {
    const response = await $http.put(
      `/restaurant/transfer/${tableId}`,
      {},
      { params: { newTableId } },
    )
    return response.data
  }

  getCategoriesByTablePOS = async (tableId, posId) => {
    const response = await $http.get(`/restaurant/categories/${tableId}`, {
      params: { posId },
    })
    return response.data
  }

  getItems = async (tableId, posId, params) => {
    const response = await $http.get(`/restaurant/items/${tableId}`, {
      params: { posId, ...params, withInventory: true },
    })
    return response.data
  }

  onUpdateItems = async (orderId, request) => {
    const response = await $http.put(`/restaurant/update/items/${orderId}`, request)
    return response.data
  }

  getPreBillCorrelative = async params => {
    const response = await $http.get('/restaurant/correlative', { params })
    return response.data
  }

  getRecipe = async recipeId => {
    const response = await $http.get('/restaurant/recipe/'.concat(recipeId))
    return response.data
  }

  deleteMultiplePOS = async listPOS => {
    const response = await $http.put('/restaurant/pos/delete', listPOS)
    return response.data
  }

  onFreezeTerminal = async (terminalId, request) => {
    const response = await $http.put(`/restaurant/terminal/freeze/${terminalId}`, request)
    return response.data
  }

  getPosProfile = async posId => {
    const response = await $http.get(`/restaurant/pos/${posId}/profile`)
    return response.data
  }

  updatePosProfile = async (posId, request) => {
    const response = await $http.put(`/restaurant/pos/${posId}/profile`, request)
    return response.data
  }

  updatePosStepItems = async (posId, request) => {
    const response = await $http.put(`/restaurant/pos/config/${posId}`, request)
    return response.data
  }

  getPOSItemsType = async posId => {
    const response = await $http.get(`/restaurant/pos/${posId}/item`)
    return response.data
  }

  onCreatePosItemType = async (posId, request) => {
    const response = await $http.post(`/restaurant/pos/${posId}/item`, request)
    return response.data
  }

  onDeletePosItem = async posItemId => {
    const response = await $http.delete(`/restaurant/pos/${posItemId}/item`)
    return response.data
  }

  onUpdatePosItem = async (posItemId, request) => {
    const response = await $http.put(`/restaurant/pos/${posItemId}/item`, request)
    return response.data
  }

  generateToken = async request => {
    const response = await $http.post(`/restaurant/token/`, request)
    return response.data
  }

  verifyToken = async token => {
    const response = await $http.post(`/restaurant/token/validate?token=${token}`)
    return { response: response.data }
  }

  getOrderPayments = async orderId => {
    const response = await $http.get(`/restaurant/order/payment/${orderId}`)
    return { response: response.data }
  }

  getPosConfigurations = async posId => {
    const response = await $http.get(`/restaurant/pos/configuration/${posId}`)
    return { response: response.data }
  }

  getPosConfigurationByUser = async (posId, posUserId) => {
    const response = await $http.get(
      `/restaurant/pos/configuration/${posId}/${posUserId}`,
    )
    return { response: response.data }
  }

  createPosConfiguration = async request => {
    const response = await $http.post('/restaurant/pos/configuration', request)
    return { response: response.data }
  }

  updatePosConfiguration = async request => {
    const response = await $http.put('/restaurant/pos/configuration', request)
    return { response: response.data }
  }

  togglePosConfiguration = async configurationId => {
    const response = await $http.put(
      `/restaurant/pos/configuration/toggle/${configurationId}`,
    )
    return { response: response.data }
  }

  deletePosConfiguration = async configurationId => {
    const response = await $http.delete(
      `/restaurant/pos/configuration/${configurationId}`,
    )
    return { response: response.data }
  }

  assignPosConfigurationUsers = async (configurationId, posUserIds) => {
    const response = await $http.put(
      `/restaurant/pos/configuration/user/${configurationId}`,
      posUserIds,
    )
    return { response: response.data }
  }

  getOrderConcepts = async () => {
    const response = await $http.get('/restaurant/pos/concept')
    return { response: response.data }
  }

  getPosConcepts = async posId => {
    const response = await $http.get(`/restaurant/pos/concept/${posId}`)
    return { response: response.data }
  }

  createPosConcept = async request => {
    const response = await $http.post('/restaurant/pos/concept', request)
    return { response: response.data }
  }

  updatePosConcept = async request => {
    const response = await $http.put('/restaurant/pos/concept', request)
    return { response: response.data }
  }

  deletePosConcept = async conceptId => {
    const response = await $http.delete(`/restaurant/pos/concept/${conceptId}`)
    return { response: response.data }
  }

  getPosTransfersTransformations = async params => {
    const response = await $http.get(`/inventory/transfers`, {
      params,
    })
    return response.data
  }

  getProductPricesByPOS = async posId => {
    const response = await $http.get('/restaurant/product/prices/' + posId)
    return response.data
  }

  updateProductPrices = async (posId, request) => {
    const response = await $http.put('/restaurant/product/prices', request, {
      params: { posId },
    })
    return response.data
  }

  loadProductPrices = async (posId, index, request) => {
    const response = await $http.post('/restaurant/product/prices/csv', request, {
      params: { posId, index },
    })
    return response.data
  }

  deleteProductPrice = async (posId, id) => {
    const response = await $http.delete('/restaurant/product/prices', {
      params: { posId, id },
    })
    return response.data
  }

  getCSVTemplateForProductPrices = async posId => {
    const response = await $http.get('/restaurant/product/prices/csv/template/' + posId, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/csv' })
    const fileName = 'precios-pos.csv'
    saveAs(file, fileName)
  }
}

export default new RestaurantService()
