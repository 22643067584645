import React, { Component } from 'react'

import './Card.scss'
import IconButton from '../buttons/IconButton'
import { faLink } from '@fortawesome/free-solid-svg-icons'

export default class Card extends Component {
  state = {
    copied: false,
  }

  render() {
    return (
      <div
        className={`card ${this.props.className || ''} ${
          this.props.ribbon ? 'hide-overflow' : ''
        }`}
        style={this.props.style}>
        {this.props.status && (
          <div className={`item-status ${this.props.statusColor || 'green'}`}>
            {this.props.status}
          </div>
        )}
        {this.props.ribbon && (
          <div
            className={`corner-ribbon ${this.props.ribbonPosition || 'top-left'} ${
              this.props.ribbonColor || 'green'
            } shadow`}>
            {this.props.ribbon}
          </div>
        )}
        {this.props.title ? (
          <div
            className={`card-header ${
              this.props.white ? 'ch-white' : this.props.accent ? 'kolo_blue' : ''
            }`}
            style={this.props.header}>
            <div style={this.props.titleStyle}>
              <label style={{ display: 'flex', margin: 0 }}>
                {this.props.title}
                {this.props.icon && (
                  <IconButton
                    style={{ marginLeft: '5px' }}
                    icon={faLink}
                    color={'#73879C'}
                    tooltip={
                      this.state.copied
                        ? 'Link copiado al portapapeles'
                        : 'Copiar link para ver los videos de la funcionalidad'
                    }
                    onClick={() => {
                      let aux = document.createElement('input')
                      aux.setAttribute(
                        'value',
                        `${window.location.origin}/videos/${this.props.version}/${this.props.id}`,
                      )
                      document.body.appendChild(aux)
                      aux.select()
                      document.execCommand('copy')
                      document.body.removeChild(aux)
                      this.setState({ copied: true })
                      setTimeout(() => {
                        this.setState({ copied: false })
                      }, 2000)
                    }}
                  />
                )}
              </label>
            </div>
            <div className={'button-icon'} style={{ alignSelf: 'center' }}>
              {this.props.button}
            </div>
          </div>
        ) : (
          this.props.button && (
            <div className={`button-icon`} style={this.props.buttonStyle}>
              {this.props.button}
            </div>
          )
        )}
        <div
          className={`card-body ${this.props.bodyClass || ''}`}
          style={this.props.bodyStyle}>
          {this.props.children}
        </div>
        {this.props.footer && <div className={`card-footer`}>{this.props.footer}</div>}
      </div>
    )
  }
}
