const companyFields = {
  cardSurcharge: 86,
  automaticCardSurcharge: 140,
  automaticApproveDepositsFromSale: 143,
  useExperimentalBalance: 145,
  seeMeasureUnit: 146,
  useDraftPurchases: 148,
  useDraftExpenses: 149,
  useExportationInvoice: 151,
  spreadsheetId: 158,
  salesSheetId: 159,
  itemsSheetId: 160,
  cxcSheetId: 161,
  pendingDispatchSpreadsheetId: 170,
  pendingDispatchSheetId: 171,
  kardexSpreadsheetId: 172,
  kardexSheetId: 173,
  cxcSpreadsheetId: 175,
  cxcDetailSheetId: 176,
}

export const companyFieldsPurchase = {
  addTaxDetailed: 168,
}

export const companyFieldsFel = {
  useFEL: 90,
}

export const companyFieldsQuote = {
  quoteContactByUser: 164,
  quoteNameFieldReference: 165,
  quoteNameFieldRepresentative: 166,
}

export const companyFieldsSell = {
  canChangeInventoryReturnOptionByCompany: 142,
  useDraftSale: 144,
  enabledZeroPrices: 147,
  experimentalConfirmSale: 152,
}

export const companyFieldsInvoice = {
  automaticFEL: 117,
  invoiceCredit: 154,
  footerInvoiceCredit: 155,
  taxDetailed: 156,
  taxIncluded: 157,
  showUserCreator: 162,
  sellWithSFCCorrelative: 167,
  qrInvoice: 169,
  enableViewSellPaymentsIntoBillTicket: 174,
}

export const companyFieldsTurns = {
  transactionsWithActiveTurn: 177,
}

export default companyFields
