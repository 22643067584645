enum RouteMapping {
  Home = 'home',
  perfil = 'profile',
  Settings = 'settings',
  Login = 'login',
  Register = 'register',
  ' ' = 'dashboard', // Map empty string route to "dashboard"
}

export default RouteMapping
