import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import { Geocoding, FormText, Card, Icon, Select, SwitchV2 } from 'src/components'

import {
  actionTypes,
  createClient,
  getInfoNIT,
  onCreateClientStep,
} from 'src/actions/clients.actions'
import {
  selectCreate,
  selectNITinfo,
  selectSingleClient,
} from 'src/selectors/clients.selector'
import {
  faArrowLeft,
  faArrowRight,
  faMapMarkerAlt,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'src/components/buttons/Button'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import { history } from 'src/content/Clients/Create/SpecificClient'
import InfoNit from 'src/components/InfoNit/InfoNit'
import useDebounce from 'src/hooks/useDebounce'
import { selectNeedAddressComponents, valNit } from 'src/selectors/utilities.selector'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import Folder, { categoryType } from 'src/components/folders/Folder'
import { InfoCUI } from 'src/components/infoCUI/InfoCUI'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import {
  selectAllRegionsForSelect,
  selectRegionLocalitiesForSelect,
} from 'src/selectors/address.selector'
import { getAllRegions, getLocalitiesByRegion } from 'src/actions/address.actions'
import {
  selectEconomicActivities,
  selectExportationCountries,
} from 'src/selectors/currencies.selector'
import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectIdentificationTypes,
} from 'src/selectors/company.selector'
import {
  getAllEconomicActivity,
  getExportationCountries,
} from 'src/actions/currencies.actions'
import { Country } from 'src/enums/countries'
import IdentificationField from 'src/components/IdentificationField/IdentificationField'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import companyFields from 'src/enums/companyFields'
import { getCompanyField } from 'src/actions/company.actions'
import { isNecessaryAddressComponents } from 'src/utils/clientHelpers/clientFunctions'
import { showAlert } from 'src/actions/alert.actions'

const defaultOption = { value: null, label: 'Sin seleccionar' }

const RequiredInformation = ({ jumpToStep, inModal, onlyCloseModal }) => {
  const dispatch = useDispatch()

  const client = useSelector(selectCreate)
  const infoNIT = useSelector(selectNITinfo)
  const response = useSelector(selectSingleClient)
  const categorization = useSelector(selectAllCategorizations)
  const economicActivities = useSelector(selectEconomicActivities)
  const country = useSelector(selectCompanyCountry)

  const setAddressComponents = useSelector(selectNeedAddressComponents)

  const regions = useSelector(selectAllRegionsForSelect)
  const localities = useSelector(selectRegionLocalitiesForSelect)
  const identificationTypes = useSelector(selectIdentificationTypes)
  const exportationCountries = useSelector(selectExportationCountries)
  const useExportationInvoice = useSelector(state =>
    selectCompanyFieldById(state, companyFields.useExportationInvoice),
  )

  const [error, setError] = useState({})
  const [geoModal, setGeoModal] = useState(false)
  const [actions, setActions] = useState({ create: false })

  const [clientActivity, setClientActivity] = useState(null)
  const [showCategorizations, setShowCategorizations] = useState(false)

  const loadingClient = useSelector(state =>
    loadingSelector([actionTypes.CREATE_SINGLE_CLIENTS])(state),
  )
  const hasErrorClient = useSelector(state =>
    hasErrorsSelector([actionTypes.CREATE_SINGLE_CLIENTS])(state),
  )
  const errorClient = useSelector(state =>
    singleErrorSelector([actionTypes.CREATE_SINGLE_CLIENTS])(state),
  )

  const loadingNit = useSelector(state =>
    loadingSelector([actionTypes.GET_NIT_INFO])(state),
  )
  const hasErrorNit = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_NIT_INFO])(state),
  )

  const handleNitSearch = useDebounce(nit => {
    if (!nit || nit === 'CF' || !valNit(nit)) return

    dispatch(getInfoNIT(nit))
  }, 1000)

  const changeValue = ev => {
    const { id, value } = ev.target
    dispatch(onCreateClientStep({ ...client, [id]: value }))
  }

  useEffect(() => {
    dispatch(getAllCategorizations(5))
    dispatch(getAllRegions())
    dispatch(getAllEconomicActivity())
    dispatch(getExportationCountries())
    dispatch(getCompanyField(companyFields.useExportationInvoice))
  }, [])

  useEffect(() => {
    if (loadingClient) setActions({ ...actions, create: true })
    else if (actions.create) {
      const alert = hasErrorClient
        ? handlerError(errorClient.response.data.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              dispatch(onCreateClientStep({}))

              if (onlyCloseModal) {
                onlyCloseModal(response)
              } else history.goBack()
            },
          }

      dispatch(showAlert(alert))
    }
  }, [loadingClient])

  useEffect(() => {
    if (loadingNit) setActions({ ...actions, search: true })
    else if (actions.search) {
      if (hasErrorNit) {
        dispatch(onCreateClientStep({ ...client, companyName: '', businessName: '' }))
        dispatch(
          showAlert({
            ...handlerError('No se pudo obtener la información con el NIT indicado'),
          }),
        )
      } else {
        dispatch(
          onCreateClientStep({
            ...client,
            companyName: infoNIT.clientName,
            businessName: infoNIT.clientName,
          }),
        )
      }
    }
  }, [loadingNit])

  const getData = data => {
    let latitude = data.latLng.lat
    let longitude = data.latLng.lng
    let address = data.address
    dispatch(onCreateClientStep({ ...client, latitude, longitude, address }))
  }

  const validate = () => {
    let errors = {}
    if (!client.nit) errors.nit = 'Este campo es requerido'
    if (!client.companyName) errors.companyName = 'Este campo es requerido'
    if (!client.address) errors.address = 'Este campo es requerido'
    if (client.cui && client.cui.length < 13) errors.cui = 'El Cui debe tener 13 dígitos'

    if (country.id === Country.SV) {
      const clientDui = client.identifications?.[identificationTypesEnum.DUI]
      if (clientDui) {
        let dui = clientDui.value
        if (dui.length < 8 && dui !== '')
          errors.clientIdentifications = 'El DUI debe tener 8 dígitos'
      }
    }

    if (Object.keys(errors).length > 0) {
      setError(errors)
      return
    }

    let { identifications, viewers, selectedCategorization, ...clientData } = client
    const images = client.src ? [client.src] : []

    let identificationsValues = []
    if (identifications) identificationsValues = Object.values(identifications)

    const request = {
      ...clientData,
      businessType: 1,
      phoneCompany: 1,
      town: 1,
      contactType: 1,
      vendor: 0,
      deliverer: 0,
      callDay: 0,
      visitDay: 0,
      localityId: clientData.locality?.id,
      date: clientData.date ? clientData.date.valueOf() : undefined,
      identifications: identificationsValues,
      viewers: viewers?.map(viewer => viewer.value),
      exportationCountry: clientData?.exportationCountry?.value,
    }

    dispatch(createClient(request, images, selectedCategorization))
  }

  const isNextStepDisabled = () => {
    let disabled = !client.nit || !client.companyName || !client.address
    if (country.id === Country.SV) {
      if (!client.email) return true
      if (!client.exportationCountry && (!client.region || !client.locality)) return true
    }

    return disabled
  }

  return (
    <div>
      <Row>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Row>
            <Col xl={12}>
              <ImageInput
                imageType={imageTypes.CLIENT}
                onSuccessFile={(url, name) => {
                  dispatch(onCreateClientStep({ ...client, src: { url, name } }))
                }}
                src={client.src}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
          <Card>
            <Row>
              {country.id === Country.GT ? (
                <>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <InfoNit
                      nit={client.nit}
                      onChange={ev => {
                        changeValue(ev)
                        handleNitSearch(ev.target.value)
                      }}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <InfoCUI
                      cui={client.cui}
                      onChange={changeValue}
                      onChangeName={name => {
                        dispatch(onCreateClientStep({ ...client, companyName: name }))
                      }}
                      error={error.cui}
                    />
                  </Col>
                </>
              ) : (
                identificationTypes
                  .filter(identificationType => identificationType.countryId !== null)
                  .map(identificationType => (
                    <Col key={identificationType.id} xl={4} lg={4} md={4} sm={12}>
                      <IdentificationField
                        identificationTypeId={identificationType.id}
                        value={client.identifications?.[identificationType.id]?.value}
                        noValidate={identificationType.id === 5}
                        onChange={value => {
                          const newIdentifications = { ...client.identifications }
                          newIdentifications[identificationType.id] = {
                            identificationTypeId: identificationType.id,
                            value,
                          }
                          dispatch(
                            onCreateClientStep({
                              ...client,
                              identifications: newIdentifications,
                            }),
                          )
                        }}
                      />
                    </Col>
                  ))
              )}
              {identificationTypes
                // este filtro se eliminará cuando las identificaciones de GT se adapten al nuevo sistema
                .filter(identificationType => identificationType.countryId === null)
                .map(identificationType => (
                  <Col key={identificationType.id} xl={4} lg={4} md={4} sm={12}>
                    <IdentificationField
                      identificationTypeId={identificationType.id}
                      value={client.identifications?.[identificationType.id]?.value}
                      noValidate={identificationType.id === 5}
                      onChange={value => {
                        const newIdentifications = { ...client.identifications }
                        newIdentifications[identificationType.id] = {
                          identificationTypeId: identificationType.id,
                          value,
                        }
                        dispatch(
                          onCreateClientStep({
                            ...client,
                            identifications: newIdentifications,
                          }),
                        )
                      }}
                    />
                  </Col>
                ))}
            </Row>
            <Row>
              <Col xl={4} lg={4} md={4} sm={12}>
                <FormText
                  label={'Código'}
                  id={'code'}
                  placeholder={'ABC123'}
                  value={client.code}
                  onChange={changeValue}
                />
              </Col>
              <Col xl={4} lg={4} md={4} sm={12}>
                <FormText
                  label={'Nombre comercial'}
                  id={'companyName'}
                  disabled={loadingNit}
                  value={client.companyName}
                  info={'Es el nombre con el que es conocido el cliente.'}
                  error={error.companyName}
                  required
                  onChange={changeValue}
                />
              </Col>
              <Col xl={4} lg={4} md={4} sm={12}>
                <FormText
                  label={'Teléfono'}
                  id={'phone'}
                  value={client.phone}
                  type={'phone'}
                  onChange={changeValue}
                />
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <FormText
                  label={'Razón social'}
                  id={'businessName'}
                  disabled={loadingNit}
                  value={client.businessName}
                  info={
                    'Es el nombre con el que se generan facturas en órdenes de venta.'
                  }
                  onChange={changeValue}
                />
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <FormText
                  label={'Correo Electrónico'}
                  id={'email'}
                  value={client.email}
                  type={'email'}
                  onChange={changeValue}
                  error={error.email}
                />
              </Col>
              {country.id === Country.SV && (
                <>
                  <Col xl={6} lg={6} md={6} sm={12} className="mb-2">
                    <Select
                      label={'Actividad económica'}
                      value={clientActivity}
                      info={'Actividad económica del cliente para emission de FEL'}
                      options={economicActivities}
                      onChange={value => {
                        setClientActivity(value)
                        dispatch(
                          onCreateClientStep({ ...client, economicActivity: value.id }),
                        )
                      }}
                      error={error.economicActivity}
                      dataCy={'economicActivity'}
                    />
                  </Col>
                  {useExportationInvoice && (
                    <Col xl={6} lg={6} md={6} sm={12} className="mb-2">
                      <Select
                        label={'País de exportación'}
                        value={client.exportationCountry}
                        info={'País del cliente para emisión de Facturas de exportación'}
                        options={[
                          { value: null, label: 'Ninguno' },
                          ...exportationCountries,
                        ]}
                        onChange={exportationCountry => {
                          dispatch(
                            onCreateClientStep({
                              ...client,
                              exportationCountry,
                            }),
                          )
                        }}
                        error={error.exportationCountry}
                        dataCy={'exportationCountry'}
                      />
                    </Col>
                  )}
                </>
              )}

              {country.id === Country.HN && (
                <Col xl={12} lg={12} sm={12}>
                  <Row>
                    <Col xl={12} sm={12}>
                      <SwitchV2
                        label={'Exonerado de impuestos'}
                        info={
                          'Cuando esta opción está marcada, no se detallará el impuesto en las facturas emitidas a este cliente'
                        }
                        checked={client.taxExempt}
                        onChange={taxExempt =>
                          dispatch(onCreateClientStep({ ...client, taxExempt }))
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              )}

              <Col xl={12} lg={12} md={12} sm={12}>
                <FormText
                  label={
                    'Escribe una dirección o ingresa en el mapa dándole click al botón de la izquierda'
                  }
                  value={client.address}
                  error={error.address}
                  required
                  id={'address'}
                  prepend={
                    <Icon
                      icon={faMapMarkerAlt}
                      onClick={() => {
                        dispatch(onCreateClientStep({ ...client, address: null }))
                        setGeoModal(true)
                      }}
                      tooltip={'Abrir mapa'}
                    />
                  }
                  placeholder={'Dirección'}
                  onChange={changeValue}
                />
              </Col>

              {!client.exportationCountry?.value && setAddressComponents && (
                <>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <CustomSelect
                      value={client.region}
                      options={[defaultOption, ...regions]}
                      label={'Departamento'}
                      required={isNecessaryAddressComponents(client.address)}
                      placeholder={'Seleccionar'}
                      name={'region'}
                      onChange={region => {
                        dispatch(getLocalitiesByRegion(region.value))
                        dispatch(
                          onCreateClientStep({ ...client, region, locality: null }),
                        )
                      }}
                      error={error.region}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <CustomSelect
                      disabled={!client.region}
                      value={client.locality}
                      options={[defaultOption, ...localities]}
                      label={'Municipio'}
                      required={isNecessaryAddressComponents(client.address)}
                      placeholder={'Seleccionar'}
                      name={'locality'}
                      onChange={locality => {
                        dispatch(onCreateClientStep({ ...client, locality }))
                      }}
                      error={error.locality}
                    />
                  </Col>
                </>
              )}

              <Col xl={12} lg={12} md={12} sm={12}>
                <FormText
                  id={'description'}
                  label={'(Opcional) Ingresa una descripción'}
                  as={'textarea'}
                  rows={5}
                  max={2000}
                  value={client.description}
                  placeholder={'Descripción'}
                  onChange={changeValue}
                />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                style={{ textAlign: 'left', margin: '20px 0' }}>
                <Button onClick={() => setShowCategorizations(true)}>
                  Asignar categorías
                </Button>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} style={{ textAlign: 'left' }}>
                {client.selectedCategorization?.map(category => (
                  <div key={category.id} className="chip mb-3 mb-0 ml-1">
                    <div className="chip-head">{category.name.charAt(0)}</div>
                    <div className="chip-content">{category.name}</div>
                  </div>
                ))}
              </Col>
            </Row>
          </Card>
          <hr />
          <Row className={'container-buttons'}>
            {!inModal && (
              <Button
                icon={faArrowLeft}
                color={'secondary'}
                onClick={() => {
                  dispatch(onCreateClientStep({}))
                  history.goBack()
                }}>
                Regresar
              </Button>
            )}
            <Button loading={loadingClient} onClick={() => validate()} icon={faSave}>
              Guardar
            </Button>
            <Button
              right
              disabled={isNextStepDisabled()}
              onClick={() => jumpToStep(1)}
              icon={faArrowRight}>
              Siguiente
            </Button>
          </Row>
        </Col>
      </Row>

      <Folder
        noMessage
        onHide={() => setShowCategorizations(false)}
        onAssign={item => {
          if (client.selectedCategorization?.find(i => i.id === item.id)) {
            dispatch(
              onCreateClientStep({
                ...client,
                selectedCategorization: client.selectedCategorization?.filter(
                  i => i.id !== item.id,
                ),
              }),
            )
          } else {
            dispatch(
              onCreateClientStep({
                ...client,
                selectedCategorization: client.selectedCategorization
                  ? [...client.selectedCategorization, item]
                  : [item],
              }),
            )
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorizations}
        list={client.selectedCategorization?.map(p => p.id)}
        type={categoryType.CLIENTS}
      />

      <Modal show={geoModal} size={'lg'} centered onHide={() => setGeoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Ubicación </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Geocoding
            lat={client.latitude}
            lng={client.longitude}
            defaultAddress={client.address}
            editable
            zoom
            getData={value => getData(value)}
            withoutCard
            onlyAddress
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-button'}></Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default RequiredInformation
